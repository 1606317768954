.past-winners-nav {
  display: flex;
  padding: 4.8rem 0;
  margin-bottom: 2rem;
}

.past-winners-form {
  align-self: end;
  justify-self: end;
  gap: 1.8rem;
}

.past-winners-form div {
  display: flex;
  justify-content: end;
}

.past-winners-form div select {
  width: 25rem;
}

.section-past-winners {
  padding: 5rem 2rem;
  margin-bottom: 0 !important;
}

.profile-card-container {
  display: flex;
  justify-content: center;
}

.profile-card {
  display: block;
  transition: all 0.2s;
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.075);
  border-radius: 11px;
  background-size: contain;
  background-position: center;
  overflow: hidden;
  background-repeat: no-repeat;
  /* width is 400 and height is 640*/
  width: 24rem;
  height: 38.4rem;
  cursor: pointer;
  z-index: 3;
  color: inherit;
}

/* .section-past-winners :first-child {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
} */

.profile-card-container :hover:not(p),
.selected {
  box-shadow: 0 0 0 2px #1a1a1a;
  transform: scale(0.98);
}

.selected {
  cursor: auto;
}

.past-winners-card--name {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 32.5rem;
}

.past-winners-card--title {
  text-align: center;
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 1.6rem;
  font-weight: 700;
}

.featured-past-winner-card {
  display: flex;
  justify-content: center;
  z-index: 0;
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}

.past-winner-featured-section {
  overflow: hidden;
  position: relative;
  margin-top: 0.75rem;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  height: 100%;
  box-shadow: 0 0 0 2px #1a1a1a;
  border-radius: 11px;
  padding: 2rem;
}

.featured-content-container {
  display: flex;
  flex-direction: column;
}

/* prompt cards START */
.featured-header-wrapper {
  display: flex;
  position: relative;
  min-height: 32rem;
  justify-content: center;
  margin-bottom: 3.2rem;
}

.three-cards-wrapper {
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.2rem;
}

.prompt-card-img {
  box-shadow: 0 0 0 1px #1a1a1a;
  border-radius: 11px;
  height: 32rem;
  width: auto;
}

.prompt-card-img:nth-child(1) {
  justify-self: end;
}

.prompt-card-img:nth-child(2) {
  justify-self: center;
}

.prompt-card-img:nth-child(3) {
  justify-self: start;
}

/* prompt cards END */

.past-winners-title {
  display: flex;
  flex-direction: column;
  text-align: center;
  z-index: 2;
  font-weight: 700;
  color: #1a1a1a;
  gap: 1.6rem;
  max-width: 60rem;
  bottom: 0;
  align-items: center;
  justify-content: end;
}

.btn--read-story {
  justify-self: center;
  align-self: center;
}

.read-content {
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 1rem;
}

.close {
  color: #7fa8ba;
  text-shadow: 0 1px #343434, -1px 0 #676767, -1px 2px #343434, -2px 1px #676767,
    -2px 3px #343434, -3px 2px #676767, -3px 4px #343434, -4px 3px #676767,
    -4px 5px #343434, -5px 4px #676767, -5px 6px #343434, -6px 5px #676767,
    -6px 7px #343434, -7px 6px #676767, -7px 8px #343434, -8px 7px #676767;
  margin-bottom: 0;
}

.featured-content-interview {
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  gap: 3.2rem;
}

.past-winner-interview-content {
  display: flex;
  flex-direction: column;
}

.past-winner-judges {
  margin-top: 12.8rem;
  display: flex;
  flex-direction: column;
  gap: 4.8rem;
}

.past-winner-judge-pic {
  display: block;
  width: 15rem;
  box-shadow: 0 0 0 1px #1a1a1a;
  border-radius: 11px;
  margin: 0 auto;
}

.past-winner-judge-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
