/*******************************************/
/* BELOW 720px (Smaller Tabelts) */
/*******************************************/

@media (max-width: 45em) {
  .user-image {
    height: 30rem;
    width: 20rem;
  }

  .profile-staging {
    max-width: 64rem;
  }

  .trophy {
    height: 4rem;
    width: 4rem;
  }

  .trophy-bank-wrapper {
    height: 10rem;
  }
}

/*******************************************/
/* BELOW 544px (Phones) */
/*******************************************/

@media (max-width: 34em) {
  .user-image {
    height: 16.5rem;
    width: 11rem;
  }

  .profile-staging {
    max-width: 35.5rem;
  }

  .profile-trophies {
    margin-bottom: 0.3rem;
  }

  .trophy-bank-wrapper {
    height: 6rem;
  }

  .trophy-bank {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .trophy-editable {
    border-width: 1px;
  }

  .trophy-selected {
    border-width: 1px;
  }

  .trophy-bank-item {
    border-width: 1px;
  }

  .profile-container {
    padding: 2.4rem 0;
  }
}

/*******************************************/
/* BELOW 352px (THIN PHONES) */
/*******************************************/

@media (max-width: 22em) {
  .profile-container {
    padding: 1.6rem 0;
  }

  .user-image {
    height: 15.75rem;
    width: 11.5rem;
  }

  .profile-staging {
    max-width: 34.5rem;
  }

  .trophy {
    height: 3.65rem;
    width: 3.65rem;
  }
}
