/*******************************************/
/* BELOW 1200px (Landscape tabelts) */
/*******************************************/

@media (max-width: 75em) {
  .header {
    padding: 0 3.2rem;
  }

  .main-nav-list {
    gap: 3.2rem;
  }
}

/*******************************************/
/* BELOW 992px (Tabelts) */
/*******************************************/

@media (max-width: 62em) {
  /* MOBILE NAV*/

  .notifications {
    top: 40px;
    right: -60px;
    width: 35rem;
    height: 30rem;
  }

  .notifications-pointer {
    display: none;
  }

  .header.nav-open .btn-mobile-nav {
    display: none;
  }

  .header .btn-mobile-nav {
    display: block;
    position: absolute;
    z-index: 999;
    top: 25%;
    right: 3%;
  }

  .header.nav-open .btn-mobile-nav {
    display: block;
    position: absolute;
    z-index: 999;
    top: 3%;
    right: 3%;
  }

  .mobile-specific-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .mobile-nav-logo {
    display: block;
  }

  .mobile-specific-list .social-links {
    display: flex;
  }

  .mobile-specific-list .logo {
    height: 15rem;
  }

  .main-nav {
    background-color: rgba(255, 255, 255, 0.97);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transform: translateX(100%);
    z-index: 98;

    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in;

    /* Hide Navigation */
    /* The problem with display: none
    is that we cannot do animations*/
    /* display: none; */

    /* we can't just opacity 0 things though
    because it doesn't completely remove it*/

    /* 1) Hide it visually */
    opacity: 0;

    /* 2) Make it unaccessible to mouse and keyboard */
    pointer-events: none;

    /* 3) Hide it from screen-readers*/
    visibility: hidden;
  }

  .main-nav--black {
    background-color: #1a1a1a;
  }

  .nav-open {
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    z-index: 10;
  }

  .nav-open .logo {
    display: none;
  }

  .nav-open .main-nav {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0);
  }

  .nav-open .close-outline {
    display: block;
  }

  .nav-open .menu-outline {
    display: none;
  }

  .main-nav-list {
    flex-direction: column;
  }

  .main-nav-link:link,
  .main-nav-link:visited {
    font-size: 3rem;
  }

  .mobile-logo-smaller {
    height: 15rem !important;
  }
}

/*******************************************/
/* BELOW 720px (Smaller Tabelts) */
/*******************************************/

@media (max-width: 45em) {
  .grid--footer {
    grid-template-columns: 1fr;
  }

  .logo-col {
    justify-content: center;
    align-items: center;
    grid-row: 2;
  }

  .footer-logo {
    transform: translateX(50%);
    width: 50%;
  }
}

/*******************************************/
/* BELOW 544px (Phones) */
/*******************************************/

@media (max-width: 34em) {
  .header {
    padding: 0 1.2rem;
  }

  .logo {
    height: 7rem;
  }

  .footer .container {
    padding: 1.2rem;
  }

  .grid--footer-content {
    grid-template-columns: 1.5fr 1fr;
    row-gap: 3.2rem;
    padding: 0 1.6rem;
  }

  .mobile-logo-flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobile-logo-smaller {
    height: 10rem !important;
  }
}

/*******************************************/
/* FlexGap Fix for Safari */
/*******************************************/

.no-flexbox-gap .main-nav-list li:not(:last-child) {
  margin-right: 4.8rem;
}

.no-flexbox-gap .list-item:not(:last-child) {
  margin-bottom: 1.6rem;
}

.no-flexbox-gap .list-icon:not(:last-child) {
  margin-right: 1.6rem;
}

.no-flexbox-gap .delivered-faces {
  margin-right: 1.6rem;
}

.no-flexbox-gap .meal-attribute:not(:last-child) {
  margin-bottom: 2rem;
}

.no-flexbox-gap .meal-icon {
  margin-right: 1.6rem;
}

.no-flexbox-gap .footer-row div:not(:last-child) {
  margin-right: 6.4rem;
}

.no-flexbox-gap .social-links li:not(:last-child) {
  margin-right: 2.4rem;
}

.no-flexbox-gap .footer-nav li:not(:last-child) {
  margin-bottom: 2.4rem;
}

@media (max-width: 75em) {
  .no-flexbox-gap .main-nav-list li:not(:last-child) {
    margin-right: 3.2rem;
  }
}

@media (max-width: 62em) {
  .no-flexbox-gap .main-nav-list li:not(:last-child) {
    margin-right: 0;
    margin-bottom: 4.8rem;
  }
}

/* LANDSCAPE */
@media screen and (min-width: 16em) and (max-width: 62em) and (orientation: landscape) {
  .main-nav-list {
    gap: 1rem !important;
  }

  .mobile-specific-list {
    gap: 1rem !important;
  }

  .mobile-logo-smaller {
    height: 10rem !important;
  }

  .mobile-logo-flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-nav-link:link,
  .main-nav-link:visited {
    font-size: 2rem;
  }

  .social-icon--large {
    width: 3rem;
    height: 3rem;
  }
}
