.register-overlay {
  position: fixed;
  background-color: rgba(243, 245, 242, 0.938);
  z-index: 99999999;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.register-overlay--black {
  background-color: rgba(1, 1, 1, 0.95);
}
