.editor-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #f3f5f2;
  padding: 4.8rem;
  margin-top: 2.4rem;
}

.ql-toolbar.ql-snow {
  background-color: #f3f5f2;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.ql-editor {
  padding: 10rem !important;
  font-size: 1.6rem !important;
  tab-size: 8;
  -moz-tab-size: 8;
  -o-tab-size: 8;
}

.ql-container.ql-snow {
  border: 1px solid #010101;
}

.forum-create-topic-wrapper .ql-editor {
  padding: 3.6rem !important;
  /* background-color: #f3f5f2; */
  min-height: 10rem;
}

.story-editor p {
  line-height: 1.8;
}

.submit-story-header {
  margin-top: 3.6rem;
  margin-bottom: 0;
}

.submit-story-before {
  margin-bottom: 2.4rem;
}

.story-submit-controls {
  flex-direction: row !important;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 2.4rem;
  padding: 0rem 0 2.4rem;
}

.story-title-input {
  /* intentionally actual white */
  /* background-color: white !important; */
  border: 1px solid #010101 !important;
  min-width: 30rem;
}

.submission-message {
  padding: 2.4rem 0 1.2rem 0;
  max-width: 75%;
  margin: 0 auto;
}

.story-read-header {
  padding: 2.4rem 4.8rem;
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  border-bottom: 1px solid #cdbda7;
}

.read-supporting-logo {
  width: 50rem;
  margin: 0 auto;
}

.read-icon-logo {
  width: 25rem;
  justify-self: start;
}

.read-login {
  justify-self: end;
  min-width: 20rem;
}

.story-info-container {
  padding-top: 2.4rem;
  background-color: #f3f5f2;
}

.reading-container {
  background-color: #f3f5f2;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.read-story-title {
  padding: 4.8rem 3.6rem 0 3.6rem;
  text-align: center;
}

.genre-types-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  font-weight: 700;
  gap: 1.2rem;
  border-bottom: 1px solid #cdbda7;
}

.genre-types-container,
.read-prompts {
  padding: 1.2rem 32rem;
}

.submit-story-notes {
  padding: 2rem;
}

.read-prompts-cards {
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 0;
}

.story-max-width {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 120rem;
}
