/*******************************************/
/* BELOW 1404px (smaller desktops) */
/*******************************************/
@media (max-width: 88em) {
  .genre-types-container,
  .read-prompts {
    padding: 1.2rem 28rem;
  }
}

/*******************************************/
/* BELOW 1200px (Landscape tabelts) */
/*******************************************/

@media (max-width: 75em) {
  .genre-types-container,
  .read-prompts {
    padding: 1.2rem 22rem;
  }
}

/*******************************************/
/* BELOW 992px (Tabelts) */
/*******************************************/

@media (max-width: 62em) {
  .story-section {
    padding: 4.8rem 0.25rem;
  }

  .read-login {
    min-width: 15rem;
  }

  .read-supporting-logo {
    width: 40rem;
  }

  .read-icon-logo {
    width: 20rem;
  }

  .ql-editor {
    padding: 8rem !important;
  }

  .forum-create-topic-wrapper .ql-editor {
    padding: 2.4rem !important;
  }

  .genre-types-container,
  .read-prompts {
    padding: 1.2rem 8rem;
  }
}

/*******************************************/
/* BELOW 720px (Smaller Tabelts) */
/*******************************************/

@media (max-width: 45em) {
  .read-login {
    min-width: 10rem;
  }

  .read-supporting-logo {
    width: 30rem;
  }

  .read-icon-logo {
    width: 15rem;
  }

  .ql-editor {
    padding: 3.6rem !important;
  }

  .forum-create-topic-wrapper .ql-editor {
    padding: 1.8rem !important;
  }

  .story-submit-controls {
    flex-wrap: wrap;
  }
}

/*******************************************/
/* BELOW 544px (Phones) */
/*******************************************/

@media (max-width: 34em) {
  .story-read-header {
    padding: 1.8rem;
    grid-template-columns: 1fr;
    gap: 2.2rem;
  }

  .read-login {
    min-width: 15rem;
    justify-self: center;
  }

  .read-supporting-logo {
    width: 20rem;
  }

  .read-icon-logo {
    display: none;
  }

  .editor-wrapper {
    padding: 2.8rem;
  }

  .ql-editor {
    padding: 2.8rem !important;
  }

  .forum-create-topic-wrapper .ql-editor {
    padding: 1.2rem !important;
  }

  .read-prompts {
    padding: 1.2rem;
  }

  .story-section {
    padding: 2.4rem 0.5rem;
  }
}

/*******************************************/
/* BELOW 352px (THIN PHONES) */
/*******************************************/

@media (max-width: 22em) {
  .editor-wrapper {
    padding: 1.2rem;
  }

  .ql-editor {
    padding: 1.2rem !important;
  }

  .forum-create-topic-wrapper .ql-editor {
    padding: 0.5rem !important;
  }

  .story-section {
    padding: 1.8rem 0.5rem;
  }

  .submit-story-notes {
    padding: 1.2rem;
  }
}
