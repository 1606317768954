/*******************************************/
/* BELOW 1404px (smaller desktops) */
/*******************************************/
@media (max-width: 88em) {
  .hero {
    max-width: 115rem;
    column-gap: 1rem;
  }

  .hero-description {
    font-size: 3rem;
  }

  .current-participants {
    align-items: center;
  }

  .judgeImgs-and-description {
    grid-template-columns: 1fr;
    row-gap: 3.2rem;
  }

  .judge-cards {
    justify-content: center;
    align-items: center;
  }

  .judge-description {
    justify-self: center;
    max-width: 65rem;
  }

  .testimonials-container {
    padding: 6.4rem;
  }

  .section-testimonials {
    margin-bottom: 4.8rem;
  }

  .queen-section-prize-text {
    font-size: 11rem;
  }

  .hero-description--landscape {
    margin-bottom: 2.2rem;
  }

  .current-participants {
    margin-top: 3.6rem;
    margin-left: 0rem;
  }
}

/*******************************************/
/* BELOW 1404px (smallerer desktops) */
/*******************************************/
@media (max-width: 82em) {
  .hero-title-container {
    margin-bottom: 1.2rem;
    margin-left: 0;
  }

  .judge-cards {
    margin: 0 auto;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .hero-description--landscape {
    margin-left: 0;
    font-size: 2rem;
  }

  .current-participants {
    margin-top: 3.6rem;
  }

  .first-cta-buttons {
    margin-left: 0rem;
  }

  .hero-cta-container {
    padding-top: 1.8rem;
  }
}

/*******************************************/
/* BELOW 1200px (Landscape tabelts) */
/*******************************************/

@media (max-width: 75em) {
  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }

  .testimonials-container {
    padding: 9.6rem 3.2rem;
  }

  .section-testimonials {
    margin-bottom: 3.2rem;
  }

  .queen-section-prize-text {
    font-size: 8rem;
  }
}

/*******************************************/
/* BELOW 992px (Tabelts) */
/*******************************************/

@media (max-width: 62em) {
  .hero-description {
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }

  .current-participants {
    margin-top: 2.4rem;
  }

  .landing-logo-container {
    height: 28rem;
  }

  .step-number {
    font-size: 7.4rem;
  }

  .section-how .container {
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }

  .testimonials-container {
    padding: 6.4rem 4.8rem;
  }

  .section-testimonials {
    grid-template-columns: 1fr;
    margin-bottom: 0;
  }

  .gallery {
    grid-template-columns: repeat(6, 1fr);
  }

  .hero-description--landscape {
    margin-bottom: 1rem;
    font-size: 1.8rem;
    padding-right: 0;
  }

  .current-participants {
    align-self: center;
    align-items: center;
  }

  .hero-title-container {
    margin: 0;
  }

  .preview-text--mobile {
    font-size: 1.8rem;
    padding: 0rem 2.2rem;
  }
}

/*******************************************/
/* BELOW 720px (Smaller Tabelts) */
/*******************************************/

@media (max-width: 45em) {
  .judge-cards {
    grid-template-columns: 1fr 1fr;
  }

  .hero-cta-container {
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
    padding: 3.6rem;
  }

  .landing-logo-container {
    grid-column: span 2;
    grid-row: 2;
  }

  .current-participants {
    align-self: center;
    align-items: center;
    justify-content: center;
    padding: 3.6rem 1rem;
    margin: 0;
  }

  .first-cta-container {
    grid-column: span 2;
    text-align: center;
  }

  .section-how {
    padding: 4.8rem 0 9.6rem;
  }

  .step-number {
    font-size: 6.2rem;
  }

  .step-img {
    width: 80%;
  }

  .step-img--left {
    width: 80%;
  }

  .judgeImgs-and-description {
    grid-template-columns: 1fr;
    row-gap: 3.2rem;
  }

  .judge-cards {
    justify-content: center;
    align-items: center;
  }

  .judge-cards:nth-child(odd) {
    justify-self: center;
  }

  .judge-description {
    justify-self: center;
    max-width: 54rem;
  }

  .home-page-countdown-container {
    padding: 2.4rem;
    gap: 2.4rem;
  }

  .first-cta-buttons {
    margin-left: 0rem;
  }

  .hero-description--landscape {
    text-align: center;
    font-size: 2.4rem;
    padding: 0;
    margin: 2rem 0 2rem 0;
    padding: 0 1.2rem;
  }

  .current-participants {
    margin-left: 0rem;
  }

  .queen-section-prize-text {
    font-size: 6rem;
    text-shadow: -1px -1px 0 #010101, 1px -1px 0 #010101, -1px 1px 0 #010101,
      1px 1px 0 #010101;
  }

  .cta-on-hero {
    display: none;
  }

  .cta-under-hero {
    display: block;
  }

  .first-cta-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.6rem;
  }

  .mvw-grid {
    grid-template-columns: 1fr 1fr;
  }

  .mvw-description {
    margin-top: 1.8rem;
    grid-column: 1 / -1;
  }

  .preview-flex {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3.6rem;
  }

  .preview-img,
  .preview-img--left {
    width: 40%;
  }

  .preview-text--mobile {
    font-size: 2.2rem;
  }

  .final-testimonial-grid {
    grid-template-columns: 1fr 1fr;
  }
}

/*******************************************/
/* BELOW 544px (Phones) */
/*******************************************/

@media (max-width: 34em) {
  .hero-title-container {
    text-align: right;
  }

  .queen-landscape-background {
    object-fit: cover;
    height: 35rem;
  }

  .hero-cta-container {
    padding: 1.8rem 1rem 0 0;
  }

  .judgeImgs-and-description {
    padding: 0;
  }

  .grid .judge-cards {
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
  }

  .judge-card {
    /* width is 400 and height is 640*/
    width: 20rem;
    height: 32rem;
  }

  .judge-card .list-item span {
    font-size: 1.2rem;
  }

  .more-info {
    display: none;
  }

  .card-title {
    font-size: 1.2rem;
    margin-bottom: 16.6rem;
  }

  .judge-description {
    padding: 0 2.4rem;
  }

  .clickable-accolade {
    margin-left: 1.9rem;
  }

  .accolade {
    width: 7rem;
  }

  .accolade--mvw {
    margin-top: 4.4rem;
  }

  .landing-logo-container {
    height: 22rem;
  }

  .landing-logo {
    transform: none;
  }

  .step-img-box:nth-child(2) {
    grid-row: 1;
  }

  .step-img-box:nth-child(6) {
    grid-row: 5;
  }

  /* So... the problem here is that the step
  boxes are now a bit ambiguous. The problem
  with that is that we would need to change the
  gap property of individual items.
  we can perform a trick here to transform
  the step boxes slightly.*/
  .step-img-box {
    transform: translateY(2rem);
  }

  .testimonials-container {
    padding: 3.2rem 2.4rem;
  }

  .gallery {
    grid-template-columns: repeat(4, 1fr);
    gap: 1.2rem;
  }

  .cta-text-box {
    padding: 0 2.4rem 2.4rem 2.4rem;
  }

  .hp-title {
    font-size: 1.6rem;
  }

  .home-page-countdown-container {
    padding: 1rem;
    gap: 2.4rem;
  }

  .queen-section-prize-text {
    font-size: 4.5rem;
  }

  .heading-primary--smaller-mobile {
    font-size: 4.3rem;
  }

  .step-text-box {
    margin-bottom: 2.8rem;
  }

  .preview-img,
  .preview-img--left {
    width: 50%;
  }

  .peer-vs-pro {
    flex-direction: column;
    gap: 4.2rem;
  }

  .battle-face-description-list {
    margin-top: 0;
  }
}

/*******************************************/
/* BELOW 352px (THIN PHONES) */
/*******************************************/

@media (max-width: 22em) {
  .testimonials {
    grid-template-columns: 1fr;
  }

  .hero-cta-container {
    margin-top: 4rem;
    padding-left: 1rem;
  }
  .queen-landscape-background {
    object-fit: cover;
    height: 30rem;
  }

  /* .heading-left-side {
    color: #f3f5f2;
  } */

  .grid .judge-cards {
    grid-template-columns: 1fr;
    row-gap: 1.8rem;
    margin-bottom: 1.6rem;
  }

  .hp-buttons-wrapper {
    flex-direction: column;
  }

  .how-container {
    padding: 2rem !important;
  }

  .mvw-grid {
    grid-template-columns: 1fr;
    gap: 1.8rem;
  }

  .img-smaller {
    height: 30rem;
    width: 30rem;
  }

  .final-testimonial-grid {
    grid-template-columns: 1fr;
  }

  .battle-face {
    width: 5rem;
    height: 5rem;
  }

  .battle-date {
    min-height: 10rem;
    min-width: 35rem;
  }
}
