/*
--- 01 TYPOGRAPHY SYSTEM

- Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Font weights:
Default: 400
Medium: 500
Bold: 700

Line heights:
Default: 1
Small: 1.05
Medium: 1.2
Paragraph default: 1.6

- Letter spaciong
-0.5px
-0.75px

--- 02 COLORS
Primary: #825a23
- Tints:
#f3f5f2 - our white

#f3efe9
#e6ded3
#cdbda7
#b49c7b
#9b7b4f

- Shades:
#68481c
#4e3615
#34240e
#1a1207

- Accents:
#782727
#2a6e8c
#b5ca8d
#f8dc55
#6F4776

#160e18
#2c1c2f

#8c6c91
#a991ad
#c5b5c8
#e2dae4

#b5ca8d
#91a271
#6d7955
#485138
#24281c


#935252
#ae7d7d
#c9a9a9
#e4d4d4

#601f1f
#481717
#301010
#180808

#558ba3
#7fa8ba
#aac5d1
#d4e2e8
#eaf1f4

#225870
#194254
#112c38
#08161c
#040b0e


- Greys:
#010101 - our black
#1a1a1a
#343434
#4d4d4d
#676767
#808080
#999999
#b3b3b3
#cacaca

--- 07 WHITESPACE

- Spacing system (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

*/

/*******************************************/
/* HEADER SECTION */
/*******************************************/

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e6ded3;

  /* Because we want the header to be sticky later */
  /* Which is easier to do if the height is fixed */
  height: 9.6rem;
  padding: 0 4.8rem 0 1rem;
}

.logo {
  height: 8rem;
}

/*******************************************/
/* NAVIGATION SECTION */
/*******************************************/

.main-nav-list {
  list-style: none;
  display: flex;
  gap: 4rem;
  align-items: center;
  border-bottom: rgba(1, 1, 1, 0) solid 2px;
}

.main-nav-link:link,
.main-nav-link:visited {
  display: inline-block;
  text-decoration: none;
  color: #1a1a1a;
  font-weight: 500;
  font-size: 1.8rem;
  transition: all 0.3s;
}

.main-nav-link--dark:link,
.main-nav-link--dark:visited {
  color: #f3f5f2;
}

.main-nav-link:hover,
.main-nav-link:active {
  color: #782727;
}

.main-nav-link--selected {
  font-weight: 700 !important;
  color: #782727 !important;
  border-bottom: #782727 solid 2px;
}

.login-btn,
.login-btn:link,
.login-btn:visited {
  padding: 1.2rem 2.4rem;
  border-radius: 9px;
  color: #f3f5f2;
  background-color: #2a6e8c;
  border: none;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.8rem;
}

.login-btn:hover,
.login-btn:active {
  background-color: #112c38;
  cursor: pointer;
}

/* MOBILE */

.mobile-specific-list .social-links {
  display: none;
}

.mobile-nav-logo {
  display: none;
}

.special-link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 0.7rem;
}

.btn-mobile-nav {
  border: none;
  background: none;
  cursor: pointer;

  display: none;
}

.icon-mobile-nav {
  height: 4.8rem;
  width: 4.8rem;
  color: #1a1a1a;
}

.icon-mobile-nav--white {
  color: #f3f5f2;
}

.close-outline {
  display: none;
}

/* STICKY */
.sticky .header {
  position: fixed;
  top: 0px;
  bottom: 0;
  width: 100%;
  height: 8rem;
  padding-top: 0;
  padding-bottom: 0;
  background-color: rgba(230, 222, 211, 0.97);
  z-index: 99;
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.03);
}

.sticky .section-hero {
  margin-top: 9.6rem;
}

/*******************************************/
/* GENERAL STYLES */
/*******************************************/

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  /*
  font size default is 16px
  we want to change to 10px so rem is easy to calculate
  however... user could change the default
  so we actually don't want to just change to 10px
  instead, we use a ratio
  10px / 16px = 0.625 = 62.5%
  */
  font-size: 62.5%;
  font-weight: 400;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

#root {
  overflow: hidden;
}

body {
  font-family: "Manrope", sans-serif;
  line-height: 1;
  font-weight: 400;
  color: #1a1a1a;
  background-color: #f3f5f2;
  overflow-x: hidden;
}

h2 a {
  text-decoration: none;
  color: inherit;
  border-bottom: #010101 solid 2px;
}

.border-bottom--light {
  border-bottom: #f3f5f2 solid 2px !important;
}

h2 a:hover {
  color: #343434;
  border-bottom: #343434 solid 2px;
}

.simple-underline {
  border-bottom: #010101 solid 2px;
}

.this-anchor-is-a-button {
  text-decoration: none;
  color: inherit;
}

.bold {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.heading-primary {
  font-size: 9.8rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -1px;
  color: #1a1207;
  /* text-transform: uppercase; */
}

.heading-secondary {
  font-size: 4.4rem;
  line-height: 1.2;
  margin-bottom: 9.6rem;
  color: #1a1207;
}

.heading-tertiary {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 3.2rem;
  color: #1a1a1a;
}

.subheading {
  display: block;
  font-size: 1.6rem;
  color: #68481c;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1.6rem;
  letter-spacing: 0.75px;
}

.text-align-right {
  text-align: right;
}

.description--larger {
  font-size: 2.8rem;
  line-height: 1.8;
}

.description--large {
  font-size: 2.2rem;
  line-height: 1.8;
}

.description {
  font-size: 1.8rem;
  line-height: 1.8;
}

.description-line-height--smaller {
  line-height: 1.4;
}

.description--small {
  font-size: 1.6rem;
  line-height: 1.3;
}

.description--smaller {
  font-size: 1.4rem;
  line-height: 1.3;
}

.description--smallest {
  font-size: 1rem;
  line-height: 1.2;
}

.break-long-words {
  overflow-wrap: break-word;
  word-wrap: break-word;
  /* word-break: break-all; */
  word-break: break-word;
  /* Probably add these: */
  /* -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto; */
  hyphens: auto;
}

.basic-user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid transparent;
  padding: 1.4rem;
  height: fit-content;
  cursor: pointer;
}

.basic-user-info:hover {
  border: 2px solid #010101;
}

.basic-user-info--img {
  width: 10rem;
  height: auto;
}

.basic-user-info--img-small {
  width: 5rem;
  height: auto;
}

.basic-user-info--img-smaller {
  width: 3rem;
  height: auto;
}

.basic-logo--img {
  width: 10rem;
  height: auto;
}

.basic-user-img-wrapper {
  position: relative;
  width: fit-content;
  height: fit-content;
}

.basic-user-trophy {
  position: absolute;
  height: 4rem;
  width: 4rem;
  bottom: 0;
  right: 0;
  z-index: 4;
}

.basic-user-trophy--left {
  position: absolute;
  height: 4rem;
  width: 4rem;
  bottom: 0;
  left: 0;
  z-index: 4;
}

.basic-user-trophy--img {
  display: block;
  margin: 0 auto;
  width: 75%;
}

/*******************************************/
/* REUSABLE STYLES */
/*******************************************/

.radio-button {
  transform: scale(1.5);
  accent-color: #2a6e8c;
}

.featured-content-header {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  margin-bottom: 4.8rem;
}

.read-prompt-card--duel {
  display: block;
  box-shadow: 0 0 0 1px #1a1a1a;
  border-radius: 11px;
  z-index: 1;
  width: 25%;
}

.read-prompt-card {
  display: block;
  box-shadow: 0 0 0 1px #1a1a1a;
  border-radius: 11px;
  z-index: 1;
  width: 25%;
}

.align-items--start {
  align-items: flex-start;
}

.align-items--center {
  align-items: center;
}

.align-items--end {
  align-items: end;
}

.align-self--end {
  align-self: flex-end;
}

.align-self--center {
  align-self: center;
}

.align-self--start {
  align-self: flex-start;
}

.pre-wrap {
  white-space: pre-wrap;
}

.white-space {
  white-space: pre-line;
}

.span--3 {
  grid-column: 1 / -1;
}

.justify-self--center {
  justify-self: center;
}

.justify-self--end {
  justify-self: flex-end;
}

.justify-self--grid-end {
  justify-self: end;
}

.justify-self--start {
  justify-self: start;
}

.inline-block {
  display: inline-block;
}

.indent {
  padding-left: 3.6rem;
}

.error-text {
  font-weight: 600;
  color: #301010;
  text-align: center;
}

.text-dec--none {
  text-decoration: none !important;
}

.text-color--inherit {
  color: inherit;
}

.white {
  color: #f3f5f2 !important;
}

.red {
  color: #782727 !important;
}

.red--darker {
  color: #301010 !important;
}

.black {
  color: #010101 !important;
}

.white-shadow {
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
}

.white-shadow--large {
  text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.678);
}

.white-shadow--huge {
  /* text-shadow: 4px 4px 15px white; */
  color: black;
  text-shadow: 0px 0px 5px white, 0px 0px 10px white;
}

.blue {
  color: #2a6e8c !important;
}

.light-blue {
  color: #aac5d1 !important;
}

.light-blue--background {
  background-color: #aac5d1 !important;
}

.blue-icon {
  color: #2a6e8c;
}

.dark-blue {
  color: #08161c !important;
}

.gray {
  color: #4d4d4d !important;
}

.brown {
  color: #4e3615 !important;
}

.brown-background {
  background-color: #4e3615 !important;
}

.brown-background:hover {
  background-color: #34240e !important;
}

.brown-background--lighterer {
  background-color: #f3efe9;
}

.brown-background--lighter {
  background-color: #cdbda7;
}

.brown-background--light {
  background-color: #b49c7b;
}

.red-background {
  background-color: #782727 !important;
}

.purple-background--lessDark {
  background-color: #2c1c2f;
}

.purple-background--dark {
  background-color: #160e18;
}

.purple-background--light {
  background-color: #8c6c91 !important;
}

.purple-background--lighter {
  background-color: #a991ad !important;
}

.purple-background--lightest {
  background-color: #e2dae4 !important;
}

.red-background--lighter {
  background-color: #935252 !important;
}

.green-background {
  background-color: #b5ca8d !important;
}

.yellow-background {
  background-color: #f8dc55 !important;
}

.yellow-background--dark {
  background-color: #635822 !important;
}

.gray-background {
  background-color: #4d4d4d !important;
}

.white-background-faded {
  background-color: rgba(243, 245, 242, 0.65) !important;
}

.black-background--lessDark {
  background-color: #343434;
}

.black-background {
  background-color: #1a1a1a;
}

.black-background--darker {
  background-color: #010101;
}

.black-background-faded {
  background-color: rgba(1, 1, 1, 0.35) !important;
}

.fade-in {
  animation: fadeIn 1s;
}

.fade-in--quick {
  animation: fadeIn 0.5s;
}

.fade-in--slow {
  animation: fadeIn 3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.lottie-wrapper {
  padding-top: 4.8rem;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.general-icon-box {
  border: none;
  background: none;
  cursor: pointer;
}

.general-icon-box--close {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
}

.general-icon {
  height: 4.8rem;
  width: 4.8rem;
  color: #1a1a1a;
}

.general-icon:hover {
  color: #010101;
}

.general-icon--white {
  color: #f3f5f2 !important;
}

.general-icon--white:hover {
  color: white !important;
}

.hidden {
  display: none;
}

.overlay {
  pointer-events: none;
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(130, 90, 35, 0.2);
  z-index: 1;
}

.loading {
  position: absolute;
  height: 100%;
  width: 0%;
  background-image: linear-gradient(
    to right,
    rgba(130, 91, 36, 0.1),
    rgba(130, 90, 35, 0)
  );
  background-size: 200% 100%;
  animation: sweep 1s ease-in-out infinite;
  animation-direction: alternate-reverse;
}

@keyframes sweep {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.list-item {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  font-size: 1.8rem;
}

.list-icon {
  width: 3rem;
  height: 3rem;
  color: #2a6e8c;
}

.container {
  /* 1140 px is a standard*/
  max-width: 120rem;
  padding: 0 3.2rem;
  margin: 0 auto;
}

.register-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #e6ded3;
  padding: 4.6rem;
  padding-top: 6.6rem;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1.8rem 4.8rem rgba(0, 0, 0, 0.15);
}

.checkbox {
  width: 2.4rem !important;
  height: 2.4rem !important;
  accent-color: #2a6e8c;
}

.checkbox--larger {
  width: 2.4rem !important;
  height: 2.4rem !important;
  transform: scale(2);
  accent-color: #2a6e8c;
}

.checkbox--medium {
  width: 2.4rem !important;
  height: 2.4rem !important;
  transform: scale(1.5);
  accent-color: #2a6e8c;
}

.center-box {
  max-width: 50rem;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
}

.center-vert {
  transform: translateY(25%);
}

.remove-anchor-dec {
  color: #010101;
}

.flex {
  display: flex;
}

.flex--gap-large {
  gap: 4.8rem;
}

.flex--gap-normal {
  gap: 2.4rem;
}

.flex--gap-small {
  gap: 2.4rem;
}

.flex--gap-smaller {
  gap: 1.2rem !important;
}

.flex--gap-tiny {
  gap: 0.5rem !important;
}

.flex--direction-column {
  flex-direction: column;
}

.justify-content--start {
  justify-content: flex-start;
}

.justify-content--center {
  justify-content: center;
}

.justify-content--end {
  justify-content: flex-end;
}

.justify-content--space-bet {
  justify-content: space-between;
}

.flex--justify-start {
  justify-content: start;
}

.flex--justify-space-between {
  justify-content: space-between;
}

.flex--align-center {
  align-items: center;
}

.flex--justify-self-end {
  justify-self: end;
}

.flex--justify-self-start {
  justify-self: flex-start;
}

.flex--align-self-end {
  align-self: flex-end;
}

.flex--align-self-start {
  align-self: flex-start;
}

.center-flex {
  margin: 0 auto;
}

.grid {
  display: grid;
  row-gap: 9.6rem;
  column-gap: 6.4rem;
}

.hero-background {
  background-image: linear-gradient(#e6ded3, #f3f5f2);
}

.hero-background--black {
  background-image: linear-gradient(#1a1a1a, #08161c);
}

.clickable {
  cursor: pointer;
}

.clickable-text--no-margin {
  border-bottom: 2px solid currentColor;
}

.clickable-text {
  margin: 0rem auto;
  border-bottom: 2px solid currentColor;
}

.clickable-text--no-margin:hover,
.clickable-text:hover {
  cursor: pointer;
  border-bottom: 2.5px solid currentColor;
}

.underline {
  text-decoration: underline;
}

.right-text {
  text-align: right;
}

.left-text {
  text-align: left;
}

.prompt-text-grid {
  display: grid;
  grid-template-columns: 1fr auto 1fr auto 1fr;
  text-align: center;
  margin-bottom: 1.6rem;
  justify-content: center;
  padding: 0 9.6rem;
  align-items: center;
}

/* We did this so that the last if two or more
grids were used in a second, there would be
a margin bottom, unless it's the last grid
in a section*/
/* .grid:last-child {
  margin-bottom: 0;
} */

.min-width--larger {
  min-width: 20rem;
}

.min-width--even-larger {
  min-width: 30rem;
}

.max-width--larger {
  max-width: 50rem;
}

.max-width--readable-center {
  max-width: 50rem;
  margin: 0 auto;
}

.max-width--readable-center--smaller {
  max-width: 35rem;
  margin: 0 auto;
}

.max-width--readable-center--larger {
  max-width: 80rem;
  margin: 0 auto;
}

.grid:not(:last-child) {
  margin-bottom: 9.6rem;
}

.grid--2-cols {
  grid-template-columns: repeat(2, 1fr);
}

.grid--3-cols {
  grid-template-columns: repeat(3, 1fr);
}

.grid--4-cols {
  grid-template-columns: repeat(4, 1fr);
}

.grid--5-cols {
  grid-template-columns: repeat(5, 1fr);
}

.grid--center-v {
  align-items: center;
}

.grid--center-h {
  justify-content: center;
}

.padding-left {
  padding-left: 12.8rem;
}

.padding-right {
  padding-right: 4.8rem;
}

.padding-left-right-small {
  padding: 0 3rem;
}

.padding-top {
  padding-top: 18rem !important;
}

.padding-top--sm {
  padding-top: 8rem !important;
}

.padding-top-bot--md {
  padding-top: 9rem !important;
  padding-bottom: 15rem !important;
}

.padding-top-bot--sm {
  padding-top: 4.8rem !important;
  padding-bottom: 4.8rem !important;
}

.padding-top-bot--smaller {
  padding-top: 2.4rem !important;
  padding-bottom: 2.4rem !important;
}

.padding {
  padding: 3.2rem;
}

.padding--small {
  padding: 0.8rem 1.2rem;
}

.padding--smaller {
  padding: 0.8rem;
}

.padding--none {
  padding: 0 !important;
}

.padding-bottom {
  padding-bottom: 8rem;
}

.padding-bottom-md {
  padding-bottom: 6rem;
}

.padding-bottom--sm {
  padding-bottom: 2rem;
}

.padding--md {
  padding: 2rem;
}

.accent {
  color: #782727;
}

.accent--dark {
  color: #601f1f;
}

.accent--darker {
  color: #481717;
}

.accent--lighter {
  color: #935252;
}

.accent--lightest {
  color: #ae7d7d;
}

.text-align-right {
  text-align: right;
}

.cursor--pointer {
  cursor: pointer;
}

.cursor--auto {
  cursor: auto !important;
}

.margin-auto {
  margin: 0 auto;
}

.margin-top-bottom-sm {
  margin: 1.6rem 0 !important;
}

.margin-top-bottom-md {
  margin: 3.2rem 0 !important;
}

.margin-right-sm {
  margin-right: 1.6rem !important;
}

.margin-right-smaller {
  margin-right: 0.6rem !important;
}

.margin-right-tiny {
  margin-right: 1.6rem !important;
}

.margin-left-sm {
  margin-left: 1.6rem !important;
}

.margin-left-md {
  margin-left: 3rem;
}

.margin-bottom-sm {
  margin-bottom: 1.8rem !important;
}

.margin-top-bigger {
  margin-top: 3.6rem !important;
}

.margin-top-huge {
  margin-top: 18rem !important;
}

.margin-top-smaller {
  margin-top: 1.6rem !important;
}

.margin-bottom-smaller {
  margin-bottom: 1.6rem !important;
}

.margin-bottom-md {
  margin-bottom: 3.2rem !important;
}

.margin-bottom-large {
  margin-bottom: 6.4rem !important;
}

.margin-bottom-larger {
  margin-bottom: 13rem !important;
}

.margin-bottom-huge {
  margin-bottom: 36rem !important;
}

.margin-left-right-small {
  margin: 0 3rem;
}

.margin-left-right-tiny {
  margin: 0 1rem;
}

.margin--none {
  margin: 0 !important;
}

.margin--sm {
  margin: 1rem 3.6rem 3rem 3.6rem;
}

.bottom-margin--small {
  margin: 1.8rem !important;
}

.z-index-top {
  z-index: 3;
}

.letter-spacing--larger {
  letter-spacing: 1px;
}

.letter-spacing--small {
  letter-spacing: -3px;
}

.letter-spacing--smaller {
  letter-spacing: -5px;
}

.letter-spacing-- {
  letter-spacing: -5px;
}

.move-left {
  margin-left: -6px;
}

.center-text {
  text-align: center;
}

.bottom-ish-position {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
}

.icon-button {
  position: relative;
  margin: 1rem;
  padding: 2.4rem 1.2rem;
  border-radius: 9px;
  box-shadow: 0px 12px 29px 0px rgba(0, 0, 0, 0.2);
  border: none;
  cursor: pointer;
  transition: all 0.3s;
}

.icon-button:hover,
.icon-button:active {
  box-shadow: 0px 18px 15px 0px rgba(0, 0, 0, 0.2);
}

.icon-button--clicked {
  background-color: #b5ca8d;
  box-shadow: none;
}

.btn,
.btn:link,
.btn:visited {
  position: relative;
  display: inline-block;
  font-size: 2rem;
  font-weight: 600;
  padding: 1.6rem 3.2rem;
  text-decoration: none;
  border-radius: 9px;
  box-shadow: 0px 12px 29px 0px rgba(0, 0, 0, 0.2);

  /* Only necessary for .btn */
  border: none;
  cursor: pointer;

  transition: all 0.3s;

  font-family: inherit;
}

.btn--small-font {
  font-size: 1.3rem;
}

.btn--full {
  background-color: #825a23;
  color: #f3f5f2;
  box-shadow: 0px 12px 29px 0px rgba(0, 0, 0, 0.2);
}

.btn--full:hover,
.btn--full:active {
  background-color: #4e3615;
  box-shadow: 0px 18px 15px 0px rgba(0, 0, 0, 0.2);
}

.btn--full--autumn {
  background-color: #c37959;
}

.btn--full--autumn:hover,
.btn--full--autumn:active {
  background-color: #754935;
}

.btn--full--winter {
  background-color: #80959d;
}

.btn--full--winter:hover,
.btn--full--winter:active {
  background-color: #4d595e;
}

.btn--full--spring {
  background-color: #d03f37;
}

.btn--full--spring:hover,
.btn--full--spring:active {
  background-color: #7d2621;
}

.btn--full--summer {
  background-color: #8a975f;
}

.btn--full--summer:hover,
.btn--full--summer:active {
  background-color: #535b39;
}

.btn--full:disabled {
  background-color: grey;
  cursor: auto;
  box-shadow: none;
}

.btn--outline {
  background-color: #e6ded3;
  color: #1a1a1a;
  box-shadow: 0px 12px 29px 0px rgba(0, 0, 0, 0.2);
}

.btn--outline:hover,
.btn--outline:active {
  background-color: #cdbda7;
  box-shadow: 0px 18px 15px 0px rgba(0, 0, 0, 0.2);
}

.btn--lightRed:link,
.btn--lightRed:visited {
  background-color: #e4d4d4;
  color: #1a1a1a;
  box-shadow: 0px 12px 29px 0px rgba(0, 0, 0, 0.2);
}

.btn--lightRed:hover,
.btn--lightRed:active {
  background-color: #c9a9a9;
  box-shadow: 0px 18px 15px 0px rgba(0, 0, 0, 0.2);
}

.btn--lightBlue:link,
.btn--lightBlue:visited {
  background-color: #d4e2e8;
  color: #1a1a1a;
  box-shadow: 0px 12px 29px 0px rgba(0, 0, 0, 0.2);
}

.btn--lightBlue:hover,
.btn--lightBlue:active {
  background-color: #aac5d1;
  box-shadow: 0px 18px 15px 0px rgba(0, 0, 0, 0.2);
}

.btn--register {
  margin-top: 1.2rem;
  background-color: #2a6e8c;
  color: #f3f5f2;
  padding: 1.2rem;
}

.btn--register:hover,
.btn--register:active {
  background-color: #7fa8ba;
  color: #1a1a1a;
}

.story-submission-btn:hover {
  background-color: #225870;
  color: #f3f5f2;
}

.simple-loader {
  position: absolute;
  top: 50%;
  right: 0;
  width: 40px;
  height: 40px;
  transform: translate(-50%, -50%);
}

.simple-loader--bottom-right {
  top: 10%;
}

.btn--form {
  background-color: #2a6e8c;
  color: #f3f5f2;
  align-self: end;
  padding: 1.2rem;
}

.btn--form:hover,
.btn--form:active {
  background-color: #225870;
}

.btn--form:disabled,
.btn--outline:disabled,
.btn--register:disabled {
  cursor: auto;
  background-color: #808080;
  color: #f3f5f2;
  padding: 1.2rem;
  box-shadow: none;
}

.redraw-button {
  color: #f3f5f2;
  background-color: #782727;
}

.redraw-button:hover {
  background-color: #481717;
}

.redraw-button:disabled {
  cursor: auto;
  background-color: #808080;
  color: #f3f5f2;
  box-shadow: none;
}

.button--disabled {
  cursor: auto;
  background-color: #808080;
  box-shadow: none;
}

.button--disabled:hover,
.button--disabled:active {
  background-color: #808080;
  box-shadow: none;
}

.flex-wrap {
  flex-wrap: wrap;
}

/*******************************************/
/* FOOTER */
/*******************************************/

.simple-divider {
  margin: 3.6rem 0;
  padding: 0.8rem 0;
  border-bottom: 1px solid #4d4d4d;
}

.simple-divider-brown {
  border-color: #cdbda7;
}

.simple-divider-blue {
  border-color: #2a6e8c;
}

.simple-divider--dotted {
  padding: 1.2rem 0;
  border-bottom: 1px dotted #4d4d4d;
}

.footer {
  padding: 12.8rem 0;
  border-top: 1px solid #4d4d4d;
}

.grid--footer {
  grid-template-columns: 1.5fr 4.5fr;
  align-items: center;
}

.grid--footer-content {
  display: grid;
  align-items: start;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.footer-logo-clickable {
  display: block;
  margin-bottom: 1.6rem;
}

.footer-logo {
  width: 100%;
}

.social-links {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.4rem;
  margin-bottom: 2.4rem;
}

.social-icon {
  height: 2.4rem;
  width: 2.4rem;
}

.social-icon--smaller {
  height: 1.4rem;
  width: 1.4rem;
}

.social-icon--large {
  margin-top: 2rem;
  height: 4.8rem;
  width: 4.8rem;
}

.logo-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.copyright {
  font-size: 1.4rem;
  line-height: 1.6;
  color: #767676;
  text-align: center;

  /* This is a trick to push it to the bottom
  it requires that it is in a flex container
  that has a column flex direction
  instead of margin-right, we have margin-top
  because of the change from row to col*/
  margin-top: auto;
}

.footer-heading {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 4rem;
}

.contacts {
  font-style: normal;
  font-size: 1.6rem;
  line-height: 1.6;
}

.address {
  margin-top: 2.4rem;
}

.footer-nav {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.footer-link:link,
.footer-link:visited {
  text-decoration: none;
  font-size: 1.6rem;
  color: #676767;
  transition: all 0.3s;
}

.footer-link:hover,
.footer-link:active {
  color: #343434;
}

/* FORM STUFF */

.register-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2.4rem;
}

.cta-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3.2rem;
  row-gap: 2.4rem;
}

.credit-card-form label,
.register-form label,
.cta-form label {
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 1.2rem;
}

/* For some reason do not inherit the font family
from where we defined it*/
.join-battle-select,
.register-form input,
.register-form select,
.cta-form input,
.cta-form select,
.select--myAccount {
  width: 100%;
  padding: 1.2rem;
  font-size: 1.8rem;
  font-family: inherit;
  border: none;
  color: inherit;
  background-color: #f3efe9;
  border-radius: 9px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.border-thin {
  box-shadow: 0 0 0 1px #1a1a1a;
  border-radius: 11px;
  padding: 0.5rem;
}

.border-thin--white {
  box-shadow: 0 0 0 1px #f3efe9;
}

.border-bottom-thin--green {
  border-bottom: 2px #24281c solid;
}

.border-bottom-thin--blue {
  border-bottom: 2px #08161c solid;
}

.register-form input::placeholder,
.cta-form input::placeholder {
  color: #aaa;
}

input:focus-visible {
  outline: none;
}

.register-box *:focus,
.cta *:focus,
.register-box *:focus-visible,
.cta *:focus-visible,
.register-form input:hover,
.register-form select:hover,
.cta-form input:hover,
.cta-form select:hover {
  outline: none !important;
  box-shadow: 0 0 0 0.6rem rgba(253, 242, 233, 0.301);
}

.register-form select,
.cta-form select,
.select--myAccount {
  outline: none !important;
}

.register-form select:hover,
.cta-form select:hover,
.select--myAccount:hover {
  cursor: pointer;
}

.register-form select:disabled,
.register-form select:active,
.cta-form select:disabled,
.cta-form select:active {
  cursor: auto;
  box-shadow: none;
}

.register-form input:disabled,
.register-form input:disabled:hover {
  cursor: auto;
  background-color: #b3b3b3;
  box-shadow: none;
}

.select--small {
  width: 15rem !important;
}

.select--myAccount {
  background-color: rgba(255, 255, 255, 0);
  border-radius: 0;
  text-decoration: none;
  box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
}

.select--myAccount:hover {
  box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.3);
}

/*******************************************/
/* PRICING SECTION */
/*******************************************/

.section-pricing {
  position: relative;
  padding: 9.3rem 0;
  overflow: hidden;
}

.pricing-battle {
  border-radius: 11px;

  /* because they are block elements,
  they will occupy 100% of the space of the cell
  let's change that...*/
  width: 75%;
  min-height: 67rem;
  display: flex;
  flex-direction: column;
}

/* This is kind of strange, but basically 
we want the first card to be justified
at the end so that both of these cards
are centered*/
.pricing-battle--nextSoonest {
  border: 2px solid #e6ded3;

  /* Because the border takes up 2px,
  you cann compensate by giving it a bit
  less padding*/
  padding: 4.4rem; /*used to be 4.8*/
}

.pricing-battle--first,
.pricing-battle--soonest {
  justify-self: end;
  background-color: #e6ded3;
  padding: 4.6rem;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1.8rem 4.8rem rgba(0, 0, 0, 0.15);
}

.pricing-battle--soonest::after {
  content: "Next up";
  position: absolute;
  top: 4%;
  right: -18%;

  text-transform: uppercase;
  color: #f3f5f2;
  font-size: 1.4rem;
  font-weight: 700;
  background-color: #2a6e8c;
  padding: 0.8rem 8rem;

  /* Use percentages for top and right
  to put it in the correct position*/
  transform: rotate(45deg);
}

.battle-header {
  text-align: center;
  margin-bottom: 4.8rem;
}

.pricing-name-and-logo {
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.8rem;
  padding-left: 0 !important;
  gap: 1rem;
}

.battle-icon {
  width: 5rem;
  height: 5rem;
  color: #1a1a1a;
}

.white-icon {
  color: #f3f5f2 !important;
}

.white-background {
  background-color: #f3f5f2 !important;
  background-image: none !important;
}

.white-background-new {
  background-color: #f3f5f2;
}

.blue-background {
  background-color: #d4e2e8 !important;
}

.blue-background--darker {
  background-color: #112c38 !important;
}

.battle-icon--small {
  width: 3rem;
  height: 3rem;
  color: #1a1a1a;
}

.battle-icon--large {
  width: 10rem;
  height: 10rem;
  color: #1a1a1a;
}

.battle-name {
  /* flex: 1; */
  color: #68481c;
  font-weight: 600;
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.75;
}

.pricing-dollar-and-amount {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.battle-description-text {
  font-size: 1.6rem;
  line-height: 1.6;
  color: #343434;
  margin-top: 1rem;
  /* margin-bottom: 3.2rem; */
  min-height: 6rem;
}

.battle-price {
  font-size: 6.2rem;
  font-weight: 700;
  color: #1a1a1a;
}

.dollar {
  text-align: right;
  font-size: 3rem;
  font-weight: 500;
  margin-right: 0.8rem;
}

.percent-off {
  text-align: left;
  font-size: 1.6rem;
  font-weight: 500;
  margin-left: 0.8rem;
  color: #343434;
}

.pricing-discount {
  font-size: 1.4rem;
  font-weight: 500;
  color: #343434;
}

/* Note: this is just used to add some margin
because our list is re-usable and generic */
.battle-sign-up {
  text-align: center;
  /* margin-top: 4.8rem; */
  justify-self: end;
  margin-top: auto;
}

.battle-details {
  font-size: 1.6rem;
  line-height: 1.6;
  text-align: center;
}

/*******************************************/
/* CTA SECTION */
/*******************************************/

.section-cta {
  padding: 4.8rem 0 12.8rem 0;
}

.cta {
  display: grid;
  grid-template-columns: 2fr 1fr;
  overflow: hidden;
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.15);
  border-radius: 11px;

  background-image: linear-gradient(to right bottom, #cdbda7, #825a23);
}

.cta .heading-secondary {
  color: inherit;
  margin-bottom: 3.2rem;
}

.cta-text-box {
  padding: 4.8rem 6.4rem 6.4rem 6.4rem;
  color: #1a1a1a;
}

.cta-text {
  font-size: 1.8rem;
  line-height: 1.8;
  margin-bottom: 4.8rem;
}

.cta-img-box {
  justify-self: center;
  align-self: center;
  padding-right: 6.4rem;
}

.cta-img {
  width: 100%;
}

.no-top-margin {
  margin-top: 0 !important;
}

.search-bar-container {
  margin-top: 1.4rem;
  padding: 1.4rem 2.4rem;
  box-shadow: 0 0 0 1px #1a1a1a;
  border-radius: 11px;
}

.search-bar-container--less-padding {
  padding: 0.5rem 2.4rem;
}

.no-border {
  box-shadow: none;
}

.search-bar-grid {
  display: grid;
  grid-template-columns: 3fr 2fr 2fr;
  gap: 1.8em;
  align-items: end;
}

.search-input {
  width: 100%;
  padding: 1.2rem;
  font-size: 1.8rem;
  font-family: inherit;
  border: none;
  color: inherit;
  background-color: #f3efe9;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.search-button {
  display: flex;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 0 2rem;
  justify-content: center;
  align-items: center;
  background-color: #2a6e8c;
}

.search-button:hover {
  cursor: pointer;
  background-color: #194254;
}

.search-section {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.sharing-settings--closed {
  display: flex;
  margin-bottom: 1.2rem;
  justify-content: end;
  padding: 1.2rem 4.8rem;
}

.sharing-settings {
  display: flex;
  margin-bottom: 1.2rem;
  justify-content: end;
  border-radius: 9px;
  padding: 1.2rem 4.8rem;
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.03);
}

.settings-icon {
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  color: #343434;
}

.settings-icon:hover {
  cursor: pointer;
  color: #010101;
}

.notifications-bell {
  position: relative;
  cursor: pointer;
}

.mobile-notifications--position {
  display: none;
}

.notifications-bell--onlyShowMobile {
  display: none;
}

.notifications {
  position: absolute;
  z-index: 9999999999;
  top: 40px;
  right: -30px;
  width: 40rem;
  height: 30rem;
  padding: 1rem;
  border-radius: 9px;
  background-color: #f3f5f2;
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.15);
  overflow-y: scroll !important;
}

.relative-wrap {
  position: relative;
}

.notifications-pointer {
  position: absolute;
  z-index: 99999999999;
  top: 25px;
  right: 15px;
  width: 0;
  height: 0;
  border-bottom: 25px solid #f3f5f2;
  border-left: 50px solid transparent;
}

.notifications-pointer--black {
  border-bottom: 25px solid #010101;
}

.notification-item {
  display: grid;
  grid-template-columns: 1fr 6fr 1fr;
  border-radius: 9px;
}

.notification-item:hover {
  background-color: #cacaca;
}

.notification-item--new {
  background-color: #d4e2e8;
}

.notification-item--new:hover {
  background-color: #aac5d1;
}

.notification-item--black {
  background-color: #010101 !important;
}

.notification-item--black:hover {
  background-color: #08161c !important;
}

.notification-item--new--black {
  background-color: #112c38 !important;
}

.bell-wrapper {
  position: relative;
}

.bell-new-notifications {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  width: 1.1rem;
  height: 1.1rem;
  z-index: 33;
  background-color: #2a6e8c;
}

.select-user-button {
  display: flex;
  overflow: hidden;
  box-shadow: 0 1.8rem 4.8rem rgba(0, 0, 0, 0.15);
  border-radius: 13px;
  height: 4rem;
  width: 20rem;
  margin: 1rem;
  cursor: pointer;
  /* background-color: #f3f5f2; */
}

.box-shadow--none {
  box-shadow: none;
}

.white-background-on-hover:hover {
  background-color: #f3f5f2;
}

.green-background--darker {
  background-color: #6d7955 !important;
}

.green-background--darkerer {
  background-color: #485138 !important;
}

.green-background--darkest {
  background-color: #485138 !important;
}

.green-background--darkestest {
  background-color: #24281c !important;
}

.green-background--darkestest-new {
  background-color: #24281c;
}

.green-background-on-hover:hover {
  background-color: #b5ca8d;
}

.greener-background-on-hover:hover {
  background-color: #6d7955;
}

.dark-green-background-on-hover:hover {
  background-color: #24281c;
}

.description--small-set-width {
  width: 12rem;
  overflow: hidden;
}

.description--smaller-set-width {
  width: 8rem;
  overflow: hidden;
}

.select-users-list {
  display: flex;
  flex-wrap: wrap;
}

.underline-on-hover:hover {
  text-decoration: underline !important;
}

.reply-to-message {
  margin-bottom: 1rem;
}

.simple-cta-img {
  transform: perspective(280px) rotateY(-10deg);
  transition: transform 0.3s ease 0s;
  border-radius: 9px;
  width: 20%;
  box-shadow: rgba(0, 0, 0, 0.024) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.05) 0px 1px 0px 0px, rgba(0, 0, 0, 0.03) 0px 0px 8px 0px,
    rgba(0, 0, 0, 0.1) 0px 20px 30px 0px;
}

.simple-cta-img:hover {
  transform: perspective(700px) rotateY(-5deg);
}

.simple-cta-img--left {
  transform: perspective(280px) rotateY(10deg);
  transition: transform 0.3s ease 0s;
  border-radius: 9px;
  width: 20%;
  box-shadow: rgba(0, 0, 0, 0.024) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.05) 0px 1px 0px 0px, rgba(0, 0, 0, 0.03) 0px 0px 8px 0px,
    rgba(0, 0, 0, 0.1) 0px 20px 30px 0px;
}

.simple-cta-img--left:hover {
  transform: perspective(700px) rotateY(5deg);
}

.address-col {
  display: flex;
  justify-content: center;
}

.consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 1rem 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

.battle-heading--wrapper {
  margin-bottom: 3.4rem;
}

.battle-heading {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
  max-width: 120rem;
  min-height: 25rem;
  overflow: "hidden";
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top left;
}

.battle-heading--text {
  text-align: right;
  margin: 0 2.4rem 0 60rem;
}

.banner-ads-container {
  border: #010101 2px solid;
  padding: 2rem;
  margin-bottom: 2rem;
}

.banner-ad-img {
  width: 50rem;
  height: auto;
}

.checkbox--wrapper {
  display: grid;
  grid-template-columns: 1fr 9fr;
  align-items: center;
  justify-content: center;
}

.day-night-toggle {
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  border: #010101 2px solid;
  gap: 0.3rem;
  padding: 0.3rem;
  align-items: center;
  justify-content: center;
}

.circle-icon {
  border-radius: 50%;
  border: #010101 2px solid;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #194254;
}

.white-border {
  border: #f3f5f2 2px solid;
}

.column-reverse {
  flex-direction: column-reverse;
}

.underline-color--dark {
  text-decoration-color: #010101 !important;
}

.underline-color--light {
  text-decoration-color: #f3f5f2 !important;
}

.hide-on-mobile {
  display: block;
}

.show-on-mobile {
  display: none;
}

.placeholder-white::placeholder {
  color: #f3f5f2 !important;
  opacity: 1;
}

@keyframes delayEm {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes delayEm {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
