.battle-section {
  margin: 0 auto;
  padding: 9.6rem 0rem;
}

.battle-container {
  background-image: linear-gradient(#b49c7b, #f3f5f2);
  position: relative;
  margin: 0 auto;
  max-width: 150rem;
  /* border: #010101 solid 2px; */
  border-radius: 11px;
  box-shadow: 0 0.8rem 1.8rem rgba(0, 0, 0, 0.3);
  /* 
  box-shadow: 0 1.8rem 4.8rem rgba(0, 0, 0, 0.15); */
}

.battle-container-dark {
  background-image: linear-gradient(#08161c, #194254);
}

.battle-profile {
  overflow: hidden;
  position: absolute;
  background-color: rgba(170, 197, 209, 0.85);
  border-radius: 11px;
  box-shadow: 0 1.8rem 4.8rem rgba(0, 0, 0, 0.15);
  top: 0;
  left: 0;
  width: 20rem;
  height: 33rem;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.profile-img-container {
  overflow: hidden;
  margin: 0 auto;
  width: 85%;
  border: black 1px solid;
  margin-top: 8%;
}

.battle-profile-img {
  display: block;
  width: 100%;
  transition: all 0.4s;
}

.battle-profile-img:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.battle-profile--text {
  text-decoration: none;
  color: inherit;
  border-bottom: #010101 solid 1px;
}

.battle-profile--text:hover {
  color: #343434;
  border-bottom: #343434 solid 2px;
}

.title-and-date-section {
  position: absolute;
  width: 104%;
  transform: translate(-2%, -25%);
}

.time-and-date-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.time-and-date-container {
  /* rgba(155, 123, 79, 0.9) */
  /* background: linear-gradient(
    to right,
    rgba(72, 23, 23, 0.9),
    rgb(120, 39, 39, 0.45)
  ); */
  border-radius: 11px;
  grid-column: span 3;
  display: grid;
  grid-template-columns: 2fr 1fr;
  box-shadow: 0 1.8rem 4.8rem rgba(0, 0, 0, 0.15);
}

.battle-date-title {
  /* color: rgba(243, 245, 242, 0.411) */
  color: #010101;
  display: flex;
  justify-content: space-around;
  gap: 0.5rem;
  align-items: center;
  text-align: right;
  padding: 2rem;
  margin: 0;
}

.countdown-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 3fr 1fr;
  padding: 1.5rem 2rem 2rem 2rem;
}

.countdown-unit-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.countdown-unit-container span {
  font-size: 3.2rem;
}
.countdown-unit-container p {
  font-size: 1.8rem;
}

.date-text {
  font-size: 1.4rem;
  grid-column: span 4;
}

.first-stage-container {
  display: flex;
  flex-direction: column;
  padding-top: 9.6rem;
  height: 100%;
}

.battle-cards {
  padding: 2.4rem 4.8rem 4.8rem 4.8rem;
  display: flex;
  margin: 0 auto;
  justify-content: space-around;
  gap: 4.8rem;
}

.battle-card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.lock-container {
  position: absolute;
  z-index: 2;
  background-color: rgba(255, 255, 254, 0.75);
  padding: 0.25rem;
  border-radius: 0px 9px 9px 9px;
  margin: 2.2rem;
}

.lock-icon {
  margin: 0 auto;
  cursor: pointer;
  width: 5rem;
  height: 5rem;
}

.blue-card-border {
  box-shadow: 0 0 0 2px #2a6e8c;
}

.battle-card--unlocked {
  position: relative;
  transition: all 0.2s;
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.075);
  transform: scale(1.02);
}

.battle-card--unlocked::after {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 2;
  text-align: center;
  font-weight: 700;
  content: "";
  font-size: 3.6rem;
  padding-top: 60%;
}

.battle-card--unlocked-clean::after {
  content: none;
}

.lock--noHover {
  pointer-events: none;
}

.battle-card--noHover {
  pointer-events: none;
}

.battle-card--noHover::after {
  background-color: rgba(255, 255, 255, 0.7);
}

.battle-card--unlocked:hover {
  transform: scale(1.03);
}

.hard-locked-card {
  /* 3px instead of 2px and gray*/
  box-shadow: 0 0 0 3px #808080;
}

.owned-card-border {
  box-shadow: 0 0 0 3px rgb(114, 175, 2);
}

.hard-locked-card.light-blue-card-border {
  /* 3px instead of 2px and #aac5d1*/
  box-shadow: 0 0 0 3px #aac5d1;
}

.hard-locked-icon {
  color: #808080;
}

.hard-locked-icon,
.hard-locked-card {
  cursor: auto !important;
}

.background-color-text-white {
  background-color: #fffffe;
}

.battle-card--new {
  display: block;
  background-size: contain;
  background-position: center;
  overflow: hidden;
  background-repeat: no-repeat;
  box-shadow: 0 0 0 2px #1a1a1a;
  border-radius: 11px;
  cursor: pointer;
  width: 30rem;
  height: 51rem;
}

.battle-card--top-text {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 700;
  padding: 0rem 9rem 0 9rem;
  margin-top: 2.4rem;
  margin-bottom: 41.5rem;
}

.battle-card--bottom-text {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 700;
  padding: 0rem 5.3rem 0 5.3rem;
}

.draw-prompts-controls-container {
  position: relative;
  display: flex;
  margin: 0.5rem auto;
  justify-content: space-between;
  gap: 3rem;
  box-shadow: 0 1.8rem 4.8rem rgba(0, 0, 0, 0.15);
  padding: 2.4rem 9.6rem;
  border-radius: 11px;
  background-color: #cdbda7;
}

.locked-in-date {
  position: absolute;
  top: 110%;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}

.prem-req {
  color: inherit;
  display: inline-block;
}

.mini-card {
  border-radius: 5px;
  width: 6rem;
}

.redraw-button {
  align-self: center;
  position: relative;
}

.redraw--youSure {
  padding-top: 1rem;
  font-size: 1.6rem;
  display: flex;
  color: #1a1a1a;
  width: 28rem;
  justify-content: space-around;
  align-items: center;
}

.im-sure {
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  background-color: #94c9a9;
  color: #1a1a1a;
  border-radius: 50px;
}

.im-sure--grey {
  cursor: auto;
  background-color: #777;
}

.not-sure {
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  color: #782727;
}

.redraw-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.draw-container {
  position: relative;
}

.asset-amount {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4.5rem;
  font-weight: 700;
  border-radius: 5px;
  padding: 0.4rem 1.2rem;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 1.8rem 4.8rem rgba(0, 0, 0, 0.15);
}

.draw-amount {
  position: absolute;
  top: 110%;
  left: 40%;
  transform: translate(-50%, -50%);
  font-size: 2.8rem;
  font-weight: 700;
  color: #782727;
}

.asset-title {
  position: absolute;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -40%);
  font-size: 2rem;
  font-weight: 700;
  border-radius: 5px;
  padding: 0.4rem 1.2rem;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 1.8rem 4.8rem rgba(0, 0, 0, 0.15);
}

.asset-title--expand-height {
  padding: 1.4rem 1.2rem;
}

.social-share {
  max-width: 65rem;
  margin: 0 auto;
  padding: 0 1.2rem;
}

.social-share--icon {
  width: 8rem;
  height: 8rem;
}

.social-share-icon--wrapper {
  padding: 2.4rem;
  justify-content: center;
}

/* SECOND STAGE STUFF */

.second-stage {
  min-height: 80rem;
  padding: 16rem 4.8rem 4.8rem 4.8rem;
}

.message-and-preview-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  align-items: center;
  margin: 0 16rem;
  border-radius: 11px;
  border: #010101 2px solid;
  padding: 2.4rem;
}

.duel-battle {
  margin: 3.6rem 4.8rem;
}

.duel-story-container {
  display: flex;
  flex-direction: column;
  max-width: 40rem;
}

.duel-story-select-button {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2.2rem;
  border-radius: 11px;
  gap: 1rem;
  border: #010101 2px solid;
  transition: all 0.4s;
  z-index: 5;
}

.duel-story-select-button--selectable {
  border: none;
  box-shadow: 0px 12px 29px 0px rgba(0, 0, 0, 0.2);
}

.duel-story-select-button--selectable:hover {
  cursor: pointer;
  box-shadow: 0px 18px 15px 0px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
}

.duel-prompts {
  position: absolute;
  top: -27%;
  padding: 1.2rem;
}

.duel-top-row {
  position: relative;
}

.judge-fun-icon-group {
  align-self: center;
}

.judge-fun-image-wrapper {
  position: relative;
  min-width: 10rem;
  min-height: 10rem;
}

.judge-fun-icon-group--image {
  top: 0;
  left: 0;
  position: absolute;
  width: 10rem;
  height: auto;
}

.flame {
  top: -17%;
  left: -4%;
}

.duel-bottom-row {
  margin-top: 2.4rem;
  display: flex;
  justify-content: center;
  margin: 2.4rem auto;
}

.winning-story {
  border: #2a6e8c 4px solid;
}

.losing-story {
  color: #ccc;
  border: #ccc 4px solid;
  opacity: 0.4;
}

/* STORY JUDGING SECTION */

.story-judging-section {
  border-top: #2a6e8c 1px solid;
  display: flex;
  flex-direction: column;
  padding: 0 4.8rem 16rem 4.8rem;
  gap: 0.8rem;
}

.judging-message {
  margin-top: 4.8rem;
  padding: 0 4.8rem;
}

.feedback-text-area {
  font-family: inherit;
  font-size: 1.8rem;
  margin-bottom: 2.4rem;
  min-height: 17rem;
  max-width: 100%;
  min-width: 100%;
  padding: 2.4rem;
}

/* Admin Duels */

.get-duel {
  min-height: 60rem;
  padding: 2.4rem;
}
