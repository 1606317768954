/*******************************************/
/* BELOW 992px (Tabelts) */
/*******************************************/

@media (max-width: 62em) {
  .large-tarot {
    width: 28rem;
  }

  .large-tarot-foil {
    width: 25.6rem;
  }

  .tarot-list {
    min-height: 50rem;
  }
}

/*******************************************/
/* BELOW 720px (Smaller Tabelts) */
/*******************************************/

@media (max-width: 45em) {
  .large-tarot {
    width: 22rem;
  }

  .large-tarot-foil {
    width: 20.1rem;
  }

  .tarot-list {
    gap: 0.7rem;
    min-height: 40rem;
  }

  .tarot-title {
    width: 65rem;
  }

  .tarot-reversed-icon {
    width: 3rem;
    height: 3rem;
    margin: 1.5rem;
  }

  .tarot-preview-img {
    width: 14rem;
    height: 14rem;
  }
}

/*******************************************/
/* BELOW 544px (Phones) */
/*******************************************/

@media (max-width: 34em) {
  .large-tarot {
    width: 20rem;
  }

  .large-tarot-foil {
    width: 18.28rem;
  }

  .tarot-list {
    gap: 1rem;
    min-height: 30rem;
    flex-wrap: wrap;
  }

  .tarot-title {
    width: 40rem;
    padding-bottom: 2rem;
  }
}

/*******************************************/
/* BELOW 352px (THIN PHONES) */
/*******************************************/

@media (max-width: 22em) {
  .tarot-image-list {
    grid-template-columns: 1fr 1fr;
  }
}
