.forum-nav {
  display: flex;
  padding: 4.8rem 0;
  gap: 4.8rem;
}

.under-construction-title {
  justify-self: start;
  max-width: 120rem;
  margin: 0 auto;
  text-align: left;
}

.icon-and-text {
  display: flex;
  margin: 0 auto;
  gap: 2.4rem;
}

.construction-text {
  align-self: center;
  width: 30rem;
}

.forum-editor {
  line-height: 1.2;
}

.create-topic {
  border-radius: 11px;
  background-color: #f8dc55;
  padding: 2.2rem;
}

.create-reply {
  background-color: #eaf1f4;
}

.replies-list {
  display: flex;
  flex-direction: column;
  margin: 3.6rem 0;
  gap: 2.4rem;
}

.topics-list {
  display: flex;
  flex-direction: column;
  margin: 3.6rem 0;
  border: #010101 3px solid;
}

.topic-container--new {
  display: grid;
  grid-template-columns: 2fr 8fr 5fr;
  justify-content: space-between;
  padding: 1rem;
  transition: all 0.2s;
  text-decoration: none;
  color: inherit;
  border-bottom: #010101 2px solid;
}

.topic-container--new:hover {
  background-color: #f3efe9;
}

.topic-container--new-dark:hover {
  background-color: #112c38;
}

.topic-container--new:last-child {
  border-bottom: none;
}

.topic-clickable-container {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  color: inherit;
  text-decoration: none;
}

.topic-clickable-container:hover {
  text-decoration: underline;
  cursor: pointer;
}

.topic-header {
  border: #010101 2px solid;
  padding: 1.4rem;
  border-radius: 11px;
  column-gap: 1.4rem;
  text-decoration: none;
  color: inherit;
  margin-bottom: 1rem;
}

.reply-container {
  display: flex;
  flex-direction: column;
  padding: 1.4rem;
  border: #010101 2px solid;
  border-radius: 11px;
  column-gap: 1.4rem;
  text-decoration: none;
  color: inherit;
}

.reply-container--color-border {
  border: #2a6e8c 2px solid;
}

.reply-contents {
  display: grid;
  grid-template-columns: 1fr 4fr;
  justify-content: space-between;
}

.page-dropdown {
  max-width: 8rem !important;
}

.forum-last-comment:hover {
  text-decoration: underline;
}

.delete-thing {
  display: flex;
  text-align: right;
  justify-self: center;
  align-self: flex-end;
  gap: 0.5rem;
}

.delete-thing-icon:hover {
  display: inline-block;
  cursor: pointer;
  color: #301010;
}

.delete-you-sure {
  display: flex;
  flex-direction: column;
}

.beta-read-list {
  margin: 4.8rem 0 0.5rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 3fr 3fr;
  min-height: 0; /* NEW */
  min-width: 0; /* NEW; needed for Firefox */
  padding-top: 2.4rem;
  border: #010101 2px solid;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.beta-read-list-row-border {
  border-top: 1px solid #cdbda7;
  grid-column: 1 / -1;
  margin-bottom: 1rem;
}

.beta-read-list-row-border--thicker {
  border-top: 2px solid #010101;
  grid-column: 1 / -1;
  margin-bottom: 1rem;
}

.grid-info {
  display: grid;
  grid-template-columns: 1fr 10fr;
  align-items: start;
}
