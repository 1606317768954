/* Nav stuff */

.rules-nav {
  display: flex;
  padding: 4.8rem 0;
  gap: 4.8rem;
}

.rules-form {
  align-self: end;
  justify-self: end;
  gap: 1.8rem;
}

.rules-form div {
  display: flex;
  justify-content: flex-end;
}

.rules-form div select {
  width: 28rem;
}

/* everything else */

.icon-and-heading {
  display: flex;
  gap: 1.2rem;
  margin: 0 auto;
  max-width: 120rem;
}

.glance-section {
  margin-bottom: 9.6rem;
}

.grid--glance {
  gap: 3.6rem;
  padding: 0;
}

.glance-container {
  display: flex;
  margin: 0 auto;
}

.glance-card {
  display: flex;
  flex-direction: column;
  margin: auto 0;
  border-radius: 11px;
  padding: 2.4rem 3.2rem;
}

.glance-card--odd {
  background-color: #e6ded3;
  box-shadow: 0 1.8rem 4.8rem rgba(0, 0, 0, 0.15);
}

.glance-card--even {
  border: 2px solid #e6ded3;
  padding: 2.2rem 3rem;
}

.glance-card--indent {
  padding: 2rem 0rem 2rem 3rem;
}

.rules-icon {
  height: 4.8rem;
  width: 4.8rem;
  margin: 0 auto;
  padding: 3rem 0;
}

.glance-card .heading-tertiary {
  margin: 0;
}

.official-rules-section {
  margin-bottom: 9.6rem;
}

.official-rules {
  position: relative;
}

.official-rules::before {
  position: absolute;
  left: -15%;
  content: "\2192\a0";
}

.qAndA-list {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 9.6rem;
}

.question-and-answer {
  transition: all 0.3s ease 0s;
}

.question-and-answer:first-child {
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
}

.question-and-answer:last-child {
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
}

.question-and-answer:nth-child(odd) {
  color: #f3f5f2;
  background-color: #225870;
}

.question-and-answer:nth-child(odd):hover {
  cursor: pointer;
  background-color: #194254;
}

.question-and-answer:nth-child(even) {
  background-color: #eaf1f4;
}

.question-and-answer:nth-child(even):hover {
  cursor: pointer;
  background-color: #d4e2e8;
}

.question-container {
  display: flex;
  gap: 2rem;
  padding: 0 2.4rem;
}

.question-container p {
  position: relative;
  align-self: center;
  justify-self: center;
  margin-bottom: 1.2rem;
  padding: 1rem 1rem 1rem;
}

.faq-arrow-icon {
  align-self: center;
  margin-bottom: 1.2rem;
  padding-bottom: 1rem 0;
}

.answer {
  margin-top: -2rem;
  padding: 0 6rem 1.8rem 6rem;
  width: 75%;
  padding: 1.2rem 2.4rem 1.2rem 9rem;
}

.answer--width-override {
  width: 100%;
}

/* PROMPT SECTION */

.prompts-section {
  background-color: #782727;
}

.prompts-grid {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
}

.prompt-integration {
  padding: 9.3rem 2.4rem 9.3rem 9.3rem;
}

.prompt-rules-title {
  margin-bottom: 4.8rem;
  color: #c9a9a9;
}

.subheading--prompts {
  color: #f3f5f2;
}

.prompt-type {
  display: flex;
  flex-direction: column;
  padding-bottom: 9.3rem;
}

.prompt-type .description,
.prompt-type .description--small {
  padding: 1.8rem 3.6rem;
}

.prompt-type .genre-list {
  padding: 0 3.6rem;
}

.prompt-type .heading-tertiary {
  margin-bottom: 0;
}

.rules-card {
  display: block;
  border-radius: 4px;
  margin: 0 auto;
  margin-bottom: 3.6rem;
  margin-top: 9.3rem;
  border: #010101 solid 1px;
  height: 9.6rem;
}

.genre-list {
  font-size: 1.6rem;
}

.genre-list li {
  text-decoration: none;
  list-style-type: none;
  margin-left: 1.2rem;
  line-height: 2.2rem;
}

.prompt-icon-and-title {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  align-items: center;
}

.prompt-icon-and-title .battle-icon {
  color: #f3f5f2;
  justify-self: end;
  height: 3.6rem;
  width: 3.6rem;
}

.black .battle-icon {
  color: #010101;
}

.detailed-container-grid {
  display: grid;
  grid-template-columns: 1fr 2fr 4fr 2fr;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.expand-outline {
  padding: 0.8rem 0;
  border-radius: 4px;
  border: 1px solid #cdbda7;
}

.expand-outline:hover {
  cursor: pointer;
  border: 1px solid #010101;
  background-color: rgba(243, 245, 242, 0.479);
}

.expand-outline-dark:hover {
  background-color: rgba(243, 245, 242, 0.075);
}

.judge-rules-img {
  cursor: pointer;
  width: 15em;
  height: auto;
  margin: 0.5rem;
}

.judge-rules-img--large {
  height: 38rem;
  width: auto;
  justify-self: end;
}

.judge-rules-img:hover,
.judge-rules-img--selected {
  box-shadow: 0 0 0 3px #2a6e8c;
}

.selected-rules-judge-container {
  display: grid;
  grid-template-columns: auto auto;
  margin: 1rem 0;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  border-radius: 9px;
  box-shadow: 0px 12px 29px 0px rgba(0, 0, 0, 0.2);
}

.selected-rules-judge-bio {
  justify-self: start;
  overflow-y: auto;
  padding: 2rem;
  height: 38rem;
  max-width: 70rem;
}
