/*******************************************/
/* BELOW 544px (Phones) */
/*******************************************/

@media (max-width: 34em) {
  .my-account-nav {
    width: 39rem;
  }

  .change-password {
    width: 30rem;
  }
}

/*******************************************/
/* BELOW 352px (THIN PHONES) */
/*******************************************/

@media (max-width: 22em) {
  .my-account-nav {
    width: 32rem;
  }

  .change-password {
    width: 28rem;
  }

  .settings-wrapper {
    padding: 4.8rem 1.8rem;
  }
}
