.tarot-background {
  background-image: linear-gradient(#e6ded3, #6f4776);
}

.tarot-background--dark {
  background-image: linear-gradient(#343434, #6f4776);
}

.tarot-list-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 4.8rem 0;
}

.tarot-list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  min-height: 60rem;
}

.large-tarot-img {
  position: relative;
}

.large-tarot-foil {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 32rem;
  height: auto;
  /* filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg)
    brightness(119%) contrast(119%); */
}

.large-tarot {
  display: block;
  box-shadow: 0 0 0 2px #1a1a1a;
  border-radius: 11px;
  width: 35rem;
  height: auto;
  -webkit-animation: 1s ease 0s normal forwards 1 delayEm;
  animation: 1s ease 0s normal forwards 1 delayEm;
}

.small-tarot {
  display: block;
  box-shadow: 0 0 0 2px #1a1a1a;
  border-radius: 9px;
  width: 8rem;
}

.tarot-title {
  width: 80rem;
}

.large-tarot-wrapper {
  position: relative;
}

.tarot-reversed-icon {
  position: absolute;
  transform: rotate(90deg);
  color: #6f4776;
  width: 5rem;
  height: 5rem;
  padding: 0.75rem;
  margin: 2.5rem;
  background-color: rgba(243, 245, 242, 0.6);
  border-radius: 0 9px 9px 9px;
  right: 0;
  z-index: 10;
  -webkit-animation: 1.5s ease 0s normal forwards 1 delayEm;
  animation: 1.5s ease 0s normal forwards 1 delayEm;
}

.tarot-button-wrapper {
  position: relative;
  grid-column: span 3;
}

.tarot-meanings-list-container {
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  padding-bottom: 4.8rem;
}

.tarot-meanings-list {
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 1.2rem;
}

.tarot-image-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

.tarot-preview-img {
  width: 25rem;
  height: 25rem;
  border-radius: 9px;
  box-shadow: rgba(0, 0, 0, 0.024) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.05) 0px 1px 0px 0px, rgba(0, 0, 0, 0.03) 0px 0px 8px 0px,
    rgba(0, 0, 0, 0.1) 0px 20px 30px 0px;
}

.tarot-preview-img--small {
  width: 10rem;
  height: 10rem;
}

.triangle-element--tarot {
  height: 10rem;
  background-color: #8c6c91;
  background: linear-gradient(
    to bottom right,
    #8c6c91 0%,
    #8c6c91 50%,
    #f8dc55 50%,
    #f8dc55 100%
  );
}

.triangle-element--tarot--dark {
  height: 10rem;
  background-color: #8c6c91;
  background: linear-gradient(
    to bottom right,
    #8c6c91 0%,
    #8c6c91 50%,
    #635822 50%,
    #635822 100%
  );
}
