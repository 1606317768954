/*******************************************/
/* BELOW 1200px (Landscape tabelts) */
/*******************************************/

@media (max-width: 75em) {
  .forum-nav {
    padding: 4.8rem;
  }

  .under-construction-title {
    padding: 4.8rem;
  }

  .lottie-under-construction {
    padding: 0;
  }
}

/*******************************************/
/* BELOW 992px (Tabelts) */
/*******************************************/

@media (max-width: 62em) {
  .beta-read-list {
    grid-template-columns: 1fr 2fr 2fr;
  }
}

/*******************************************/
/* BELOW 720px (Smaller Tabelts) */
/*******************************************/

@media (max-width: 45em) {
  .beta-read-list {
    grid-template-columns: 1fr;
  }
}

/*******************************************/
/* BELOW 544px (Phones) */
/*******************************************/

@media (max-width: 34em) {
  .forum-nav {
    padding: 2.4rem;
  }

  .under-construction-title {
    padding: 2.4rem;
  }

  .icon-and-text {
    flex-direction: column;
    gap: 0;
  }

  .topic-container--new {
    padding: 1rem;
  }
}

/*******************************************/
/* BELOW 352px (THIN PHONES) */
/*******************************************/

@media (max-width: 22em) {
  .forum-nav {
    gap: 1.2rem;
    padding: 2.4rem 1.8rem;
  }

  .under-construction-title {
    padding: 2.4rem 1.8rem;
  }

  .construction-text {
    align-self: center;
    width: 25rem;
  }
}
