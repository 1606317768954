.my-account {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 8rem 0;
  gap: 1.6rem;
}

.my-account-nav {
  background-color: #e6ded3;
  width: 50rem;
  border-radius: 11px;
  padding: 2.4rem;
  box-shadow: 0 1.8rem 4.8rem rgba(0, 0, 0, 0.15);
}

.settings-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #e6ded3;
  max-width: 50rem;
  border-radius: 11px;
  padding: 4.8rem;
  box-shadow: 0 1.8rem 4.8rem rgba(0, 0, 0, 0.15);
}

.change-password {
  width: 40rem;
}
