/* rem and em do NOT depend on html 
font-size in media queries!!!!!
Instead, 1rem = 1em = 16px 
We should use em and not rem because
of some bugs and em works the same way*/

/*******************************************/
/* BELOW 1404px (smaller desktops) */
/*******************************************/
@media (max-width: 88em) {
  .heading-primary {
    font-size: 8.6rem;
    letter-spacing: 0;
  }
}

/*******************************************/
/* BELOW 1200px (Landscape tabelts) */
/*******************************************/

@media (max-width: 75em) {
  html {
    /* 9px / 16px */
    font-size: 56.25%;
  }

  .grid {
    column-gap: 4.8rem;
    row-gap: 6.4rem;
  }

  .heading-secondary {
    font-size: 3.6rem;
    margin-bottom: 6.4rem;
  }

  .heading-tertiary {
    font-size: 2.4rem;
  }
}

/*******************************************/
/* BELOW 992px (Tabelts) */
/*******************************************/

@media (max-width: 62em) {
  /* 8px / 16px */
  html {
    font-size: 50%;
  }

  .cta {
    grid-template-columns: 3fr 2fr;
  }

  .notifications-bell--hideMobile {
    display: none;
  }

  .notifications-bell--onlyShowMobile {
    display: block;
  }

  .mobile-notifications--position {
    display: block;
    margin: 0 20% 0 0;
  }

  .cta-form {
    grid-template-columns: 1fr;
  }

  .btn--form {
    margin-top: 1.2rem;
  }

  .pricing-battle--first,
  .pricing-battle--soonest {
    padding: 3.2rem;
  }

  .pricing-battle--nextSoonest {
    padding: 3rem;
  }

  .main-nav-list {
    flex-direction: column-reverse;
  }

  .select--myAccount {
    font-size: 2.8rem;
  }

  .login-btn {
    font-size: 2.8rem;
  }

  .flex--gap-small {
    gap: 0.6rem;
  }

  .btn--register {
    font-size: 1.8rem;
  }

  .read-prompt-card {
    width: 50%;
  }

  .heading-primary {
    font-size: 7rem;
  }

  .search-bar-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.2em;
  }

  .search-input {
    width: 60%;
  }

  .search-section {
    gap: 1rem;
  }

  .margin-right-smaller {
    margin-right: 1rem !important;
  }

  .search-dropdown {
    max-width: none !important;
    width: 60%;
  }

  .pricing-name-and-logo {
    margin-top: 3rem;
  }

  .battle-heading--text {
    margin: 0 2.4rem 0 40rem;
  }
}

/*******************************************/
/* BELOW 720px (Smaller Tabelts) */
/*******************************************/

@media (max-width: 45em) {
  .content-banner--stacked {
    flex-direction: column;
  }

  .content-banner-buttons--unstack {
    flex-direction: row;
  }

  .mobile-left-justify {
    justify-self: left;
  }

  .mobile-right-justify {
    justify-self: right;
  }

  .mobile-justify-center {
    justify-content: center !important;
  }

  .featured-content-header {
    margin-bottom: 0;
  }

  .grid--3-cols,
  .grid--4-cols {
    grid-template-columns: repeat(2, 1fr);
  }

  .heading-primary {
    font-size: 5.4rem;
  }

  .heading-secondary {
    margin-bottom: 4.8rem;
  }

  .cta {
    grid-template-columns: 1fr;
  }

  .cta-img-box {
    grid-row: 1;
    width: 60%;
    padding: 2.4rem;
  }

  .cta-text-box {
    padding-top: 0;
  }

  .pricing-battle {
    width: 100%;
    min-height: 60rem;
  }

  .pricing-battle--first,
  .pricing-battle--soonest {
    padding: 2.4rem;
  }

  .pricing-battle--nextSoonest {
    padding: 2.2rem;
  }

  .pricing-name-and-logo {
    gap: 0.5rem;
  }

  .pricing-battle--soonest::after {
    top: 3%;
    right: -16%;
    padding: 0.8rem 6rem;
  }

  .mobile-be-gone {
    display: none;
  }

  .flex--gap-normal {
    gap: 1.4rem;
  }

  .letter-spacing--smaller {
    letter-spacing: normal;
  }

  .battle-icon--large {
    width: 7rem;
    height: 7rem;
    color: #1a1a1a;
  }

  .simple-cta-img {
    width: 40%;
  }

  .simple-cta-img--left {
    width: 40%;
  }

  .simple-cta-direction-mobile {
    flex-direction: column;
  }

  .battle-heading--text {
    margin: 0 1.2rem 0 33rem;
  }
}

/*******************************************/
/* BELOW 544px (Phones) */
/*******************************************/

@media (max-width: 34em) {
  .hide-on-mobile {
    display: none;
  }

  .show-on-mobile {
    display: block;
  }

  .banner-ad-img {
    width: 40rem;
    height: auto;
  }

  .flex--direction-column--mobile {
    flex-direction: column;
    margin-bottom: 2rem;
  }

  .search-bar-container--less-padding {
    padding: 0;
  }

  .grid {
    row-gap: 3.2rem;
  }

  .grid:not(:last-child) {
    margin-bottom: 4.8rem;
  }

  .grid--2-cols,
  .grid--3-cols {
    grid-template-columns: 1fr;
  }

  .grid--4-cols {
    padding: 0 1.6rem;
  }

  .container .grid {
    margin-bottom: 6.4rem;
  }

  .btn,
  .btn:link,
  .btn:visited,
  .btn:disabled {
    padding: 2.4rem 3.6rem;
  }

  .btn--full:link,
  .btn--full:visited,
  .btn--outline:link,
  .btn--outline:visited {
    padding: 2.4rem 3.6rem;
  }

  .battle-sign-up .btn--full,
  .battle-sign-up .btn--outline {
    padding: 2.4rem 6.4rem;
  }

  .heading-primary {
    font-size: 5rem;
  }

  .heading-secondary {
    font-size: 3rem;
  }

  .heading-tertiary {
    margin-bottom: 2.4rem;
  }

  .letter-spacing--smaller {
    letter-spacing: -2px;
  }

  .margin-right-sm {
    margin-right: 0 !important;
  }

  .move-left {
    margin-left: -3px;
  }

  .heading-left-side {
    padding-right: 0;
  }

  .heading-right-side {
    padding-left: 0;
  }

  .section-pricing {
    padding: 6.4rem 0;
  }

  .pricing-battle {
    justify-self: center;
    max-width: 38rem;
  }

  .pricing-battle--soonest::after {
    top: 6%;
    right: -9%;
    padding: 0.8rem 6rem;
  }

  .prompt-text-grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto auto;
    padding: 0 3.6rem;
    margin-top: 2.4rem;
  }

  .read-prompt-card--duel {
    width: 20%;
  }

  .story-submission-btn {
    padding: 1.2rem;
  }

  .min-width--larger {
    min-width: auto;
  }

  .basic-user-info {
    padding: 0.5rem;
  }

  .basic-user-info--img {
    width: 8rem;
    height: auto;
  }

  .basic-user-info--img-small {
    width: 4rem;
    height: auto;
  }

  .container {
    padding: 0 1rem;
  }

  .description--large {
    font-size: 1.8rem;
    line-height: 1.4;
  }

  .description--larger {
    font-size: 2.4rem;
    line-height: 1.4;
  }

  .description {
    line-height: 1.4;
  }

  .description--smallerMobile {
    font-size: 1.4rem;
  }

  .battle-icon--large {
    width: 5rem;
    height: 5rem;
    color: #1a1a1a;
  }

  .mobile-wide-smaller-text {
    font-size: 1.4rem;
  }

  .sharing-settings {
    padding: 1.2rem;
  }

  .battle-heading--wrapper {
    margin-bottom: 1rem;
  }

  .battle-heading {
    min-height: 15rem;
  }

  .battle-heading--text {
    margin: 0 2rem 0 19rem;
  }
}

/*******************************************/
/* BELOW 352px (THIN PHONES) */
/*******************************************/

@media (max-width: 22em) {
  .banner-ad-img {
    width: 30rem;
    height: auto;
  }
  .description-sm--mobile {
    font-size: 1.4rem;
  }

  .heading-primary {
    font-size: 4.3rem;
  }

  .pricing-battle--soonest::after {
    top: 5%;
    right: -13%;
    padding: 0.8rem 6rem;
  }

  .register-box {
    padding: 4.8rem 1.8rem;
  }

  .container {
    padding: 0 0.8rem;
  }

  .description--large {
    font-size: 1.6rem;
    line-height: 1.2;
  }

  .description--larger {
    font-size: 1.8rem;
    line-height: 1.2;
  }

  .basic-user-info--img {
    width: 5.5rem;
    height: auto;
  }

  .basic-user-info--img-small {
    width: 3rem;
    height: auto;
  }

  .basic-user-trophy {
    height: 3rem;
    width: 3rem;
  }

  .basic-user-trophy--img {
    width: 70%;
  }

  .mobile-smaller-text {
    font-size: 1.4rem;
  }

  .mobile-wide-smaller-text {
    font-size: 1.2rem;
  }

  .min-width--even-larger {
    min-width: 20rem;
  }

  .btn,
  .btn:link,
  .btn:visited,
  .btn:disabled {
    padding: 1.8rem 1.6rem;
  }

  .btn--full:link,
  .btn--full:visited,
  .btn--outline:link,
  .btn--outline:visited {
    padding: 1.8rem 1.6rem;
  }

  .battle-heading {
    min-height: 10rem;
  }

  .battle-heading--text {
    margin: 0 2rem 0 1rem;
  }
}

@media screen and (min-width: 20em) and (max-width: 62em) and (orientation: landscape) {
  .register-form {
    gap: 1rem;
  }

  .simple-divider {
    margin: 1rem 0;
  }

  .register-form label {
    margin-bottom: 0.5rem;
  }

  .register-form input {
    padding: 0.5rem;
  }
}

@media screen and (max-height: 680px) {
  .register-box {
    padding: 1.6rem 2.4rem;
  }

  .center-box {
    max-width: 70rem;
  }

  .simple-divider {
    margin: 1rem 0;
  }

  .btn--register {
    margin-top: 0;
  }
}

/*
  - Font sizes (px)
  10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98
*/
