.profile-container {
  padding: 9.6rem 0;
}

.profile-staging {
  margin: 0 auto;
  max-width: 80rem;
  border-radius: 11px;
  box-shadow: 0 1.8rem 4.8rem rgba(0, 0, 0, 0.15);
  overflow: hidden;
  background-color: #e6ded3;
}

.user-image {
  object-fit: cover;
  height: 37.5rem;
  width: 25rem;
}

.profile-content {
  display: flex;
  flex-direction: column;
  margin-top: -0.5%;
  background-color: #e6ded3;
  box-shadow: 1.8rem 0 4.8rem rgba(0, 0, 0, 0.5);
  gap: 1.2rem;
  padding-bottom: 4.8rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-top: 3px solid #1a1a1a;
}

.profile-name {
  margin-bottom: 0;
}

.profile-text {
  white-space: pre-line;
}

.profile-editable:hover {
  cursor: pointer;
  box-shadow: 0 0 0 2px #2a6e8c;
}

.profile-pic-container {
  display: flex;
  justify-content: center;
  position: relative;
}

.edit-picture-text {
  position: absolute;
  opacity: 0;
  background-color: white;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);
}

.profile-pic-container:hover .edit-picture-text {
  pointer-events: none;
  opacity: 1;
  cursor: pointer;
}

.profile-pic-container > input {
  display: none;
}

.editable-text-container {
  position: relative;
}

.editable-text--icon {
  position: absolute;
  opacity: 0;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
}

.editable-text-container:hover .editable-text--icon {
  pointer-events: none;
  opacity: 1;
  cursor: pointer;
}

.editing-location {
  display: flex;
  flex-direction: row;
  padding: 0 4.8rem;
}

.editing-socials {
  margin: 0 auto;
}

.editing-socials--grid {
  display: grid;
  justify-content: start;
  grid-template-columns: 2fr 1fr 6fr;
  align-items: center;
  gap: 1.2rem;
}

.editing-socials--grid-website {
  grid-template-columns: 1fr 10fr;
}

.profile-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
}

/* TROPHIES */

.profile-trophies {
  align-self: flex-end;
  margin-bottom: 1.8rem;
}

.profile-trophies-display {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-content: space-between;
  gap: 0.2rem;
}

.trophy {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 4rem;
  margin: 0 auto;
}

.trophy-img {
  display: block;
  margin: 0 auto;
  width: 75%;
}

.trophy-editable {
  border-style: dashed;
  border-color: #2a6e8c;
}

.trophy-selected {
  border-style: solid;
  border-color: #b5ca8d;
}

.trophy-bank-item {
  border-style: solid;
  border-color: #f3f5f2;
}

.trophy-bank-wrapper {
  overflow-y: auto;
  height: 12rem;
}

.trophy-bank {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
}

.null-trophy {
  width: 3.8rem;
  height: 3.8rem;
}

.profile--youSure {
  padding-top: 1rem;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #1a1a1a;
  align-items: center;
}
