/*******************************************/
/* BELOW 1200px (Landscape tabelts) */
/*******************************************/

@media (max-width: 75em) {
  .heading--join-battle {
    padding: 4.8rem;
  }

  .join-battle {
    margin: 0 4.8rem;
  }

  .select-battle--controller {
    padding: 2.4rem;
  }

  .battle-select-button {
    padding: 1.1rem;
  }

  .battle-select-soonest::after {
    top: 7%;
    right: -25%;
  }

  .jb-flex--gap-small {
    gap: 1rem;
  }

  .jb-flex--gap-smaller {
    gap: 0.5rem;
  }
}

/*******************************************/
/* BELOW 992px (Tabelts) */
/*******************************************/

@media (max-width: 62em) {
  .battle-select-grid {
    padding: 1.2rem;
  }

  .select-battle--controller {
    padding: 1rem;
  }

  .battle-select-button {
    padding: 0.7rem;
  }

  .battle-select-soonest::after {
    top: 4.5%;
    right: -32%;
  }

  .jb-description {
    font-size: 1.5rem;
  }

  .jb-description--small {
    font-size: 1.2rem;
  }

  .jb-description--smaller {
    font-size: 1.1rem;
  }

  .jb-battle-icon {
    width: 3.8rem;
    height: 3.8rem;
  }

  .jb-battle-icon--small {
    width: 2rem;
    height: 2rem;
  }

  .join-battle-select {
    max-width: 11rem;
    font-size: 1.5rem;
  }

  .join-battle-select--max-width-none {
    max-width: none;
  }

  .jb-description--larger {
    font-size: 2rem;
    line-height: 1.3;
  }

  .jb-battle-name {
    font-size: 1.6rem;
  }
}

/*******************************************/
/* BELOW 720px (Smaller Tabelts) */
/*******************************************/

@media (max-width: 45em) {
  .heading--join-battle {
    padding: 2.4rem;
  }

  .join-battle {
    grid-template-columns: 1.5fr 2fr;
    margin: 0 2.4rem;
  }

  .battle-select-grid {
    grid-template-columns: 1fr;
  }

  .select-battle--controller {
    gap: 2rem;
    align-self: flex-start;
  }

  .currency-flex {
    margin-bottom: 1rem;
  }

  .checkout-frame {
    width: 60rem;
  }

  .thank-you-content {
    padding: 2rem;
  }

  .thank-you-img {
    padding: 2rem;
    height: 30rem;
  }
}

/*******************************************/
/* BELOW 544px (Phones) */
/*******************************************/

@media (max-width: 34em) {
  .checkout-buttons-mobile {
    flex-direction: column !important;
  }

  .heading--join-battle {
    padding: 1.8rem;
  }

  .join-battle {
    grid-template-columns: 1fr 1.4fr;
    margin: 0 1.8rem;
  }

  .select-battle--controller {
    padding: 0.5rem;
  }

  .jb-battle-icon {
    width: 3rem;
    height: 3rem;
  }

  .jb-battle-icon--small {
    width: 1.8rem;
    height: 1.8rem;
  }

  .jb-battle-name {
    font-size: 1.5rem;
  }

  .jb-description--larger {
    font-size: 1.8rem;
  }

  .jb-description {
    font-size: 1.3rem;
  }

  .jb-description--small {
    font-size: 1.1rem;
  }

  .jb-description--smaller {
    font-size: 1.1rem;
  }

  .battle-price {
    font-size: 4.8rem;
  }

  .percent-off {
    font-size: 1.1rem;
  }

  .jb-flex--gap-small {
    gap: 0.5rem;
  }

  .jb-flex--gap-smaller {
    gap: 0.2rem;
  }

  .currency-flex {
    flex-direction: column;
    align-self: center;
    justify-self: center;
  }

  .conversion-flex {
    text-align: center;
  }

  .checkout-btn {
    padding: 1.2rem;
    font-size: 1.4rem;
  }

  .battle-select-soonest::after {
    top: 4.5%;
    right: -42%;
    font-size: 1.2rem;
  }

  .checkout-frame {
    width: 43rem;
    padding: 3.6rem;
  }

  .thank-you-content {
    grid-column: span 2;
    grid-row: 1;
    min-height: 0;
  }

  .thank-you-img {
    grid-column: span 2;
    grid-row: 2;
    justify-self: center;
  }
}

/*******************************************/
/* BELOW 352px (THIN PHONES) */
/*******************************************/

@media (max-width: 22em) {
  .heading--join-battle {
    padding: 1rem 0.3rem;
  }

  .join-battle {
    grid-template-columns: 1fr 1.8fr;
    margin: 0 0.3rem;
  }

  .battle-select-grid {
    padding: 0.8rem;
  }

  .select-battle--controller {
    padding: 0.5rem;
  }

  .jb-battle-icon {
    width: 2rem;
    height: 2rem;
  }

  .jb-battle-name {
    font-size: 1.2rem;
  }

  .jb-description--larger {
    font-size: 1.6rem;
  }

  .jb-description {
    font-size: 1.2rem;
  }

  .percent-off {
    margin-left: 0.2rem;
  }

  .dollar {
    font-size: 2rem;
    margin-right: 0.2rem;
  }

  .battle-price {
    font-size: 3rem;
  }

  .checked-border {
    border-radius: 5px;
  }

  .battle-select-button {
    padding: 0.4rem;
  }

  .battle-select-soonest::after {
    top: 6%;
    right: -57%;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.4rem 8rem;
  }

  .checkout-frame {
    width: 33rem;
    padding: 2.4rem;
  }

  .checkout-title-and-price {
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.6rem;
  }
}
