/*******************************************/
/* BELOW 1404px (smaller desktops) */
/*******************************************/
@media (max-width: 88em) {
  .prompt-type .description {
    padding: 2rem;
  }

  .prompts-grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .prompt-integration {
    padding-left: 3.6rem;
  }

  .prompt-rules-title {
    margin-bottom: 1.8rem;
  }
}

/*******************************************/
/* BELOW 1200px (Landscape tabelts) */
/*******************************************/

@media (max-width: 75em) {
  .rules-nav {
    padding: 4.8rem;
  }

  .icon-and-heading {
    padding: 0 4.8rem;
  }

  .grid--glance {
    grid-template-columns: 1fr 1fr;
  }

  .glance-container {
    width: 55%;
    margin: 0;
  }

  .glance-container:first-child {
    justify-self: end;
  }

  .glance-container:nth-child(3) {
    justify-self: end;
  }

  .glance-text {
    text-align: center;
  }

  .prompt-integration {
    padding-left: 1.8rem;
  }

  .prompt-integration .indent {
    padding-left: 1.8rem;
  }
}

/*******************************************/
/* BELOW 992px (Tabelts) */
/*******************************************/

@media (max-width: 62em) {
  .glance-card {
    padding: 1.2rem 2rem;
  }

  .prompts-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .prompt-rules-title {
    margin-bottom: 3.6rem;
  }

  .prompt-type .description,
  .prompt-type .description--small {
    padding: 2rem 3.6rem;
  }

  .prompt-type .heading-tertiary {
    font-size: 3.6rem;
  }

  .detailed-container-grid {
    grid-template-columns: 1fr 3fr 4fr 1fr;
  }
}

/*******************************************/
/* BELOW 720px (Smaller Tabelts) */
/*******************************************/

@media (max-width: 45em) {
  .glance-container {
    width: 75%;
  }

  .answer {
    width: 90%;
  }

  .answer--width-override {
    width: 100%;
  }

  .icon-and-heading .heading-secondary {
    font-size: 3rem;
  }

  .icon-and-heading .battle-icon {
    height: 4rem;
  }

  .glance-card--indent {
    padding: 2rem 0rem 2rem 0rem;
  }
}

/*******************************************/
/* BELOW 544px (Phones) */
/*******************************************/

@media (max-width: 34em) {
  .glance-text {
    text-align: left;
  }

  .icon-and-heading {
    padding: 1.8rem;
  }

  .icon-and-heading .heading-secondary {
    font-size: 2.3rem;
  }

  .icon-and-heading .battle-icon {
    height: 3.5rem;
  }

  .rules-nav {
    padding: 2.4rem;
  }

  .grid--glance {
    margin-top: 3.6rem;
    grid-template-columns: 1fr;
    padding: 0 2.4rem;
    gap: 6rem;
  }

  .glance-container {
    width: 65%;
    justify-self: center;
  }

  .glance-container:first-child {
    justify-self: center;
  }

  .glance-container:nth-child(3) {
    justify-self: center;
  }

  .rules-form div select {
    width: 20rem;
  }

  .answer {
    width: 100%;
  }

  .prompts-grid {
    grid-template-columns: 1fr;
  }

  .prompt-integration {
    grid-row: 4;
  }

  .prompt-type .description,
  .prompt-type .description--small {
    padding: 2.2rem 8rem;
  }

  .prompt-type .genre-list {
    padding: 0 8rem;
  }

  .detailed-container-grid {
    grid-template-columns: 1fr 3fr 4fr;
  }

  .detailed-rules-span {
    grid-column: 1 / -1;
  }

  .detailed-card {
    padding: 0.5rem;
  }

  .judge-rules-img {
    width: 8em;
    height: auto;
    margin: 0.4rem;
  }

  .judge-rules-img--large {
    height: 24rem;
    width: auto;
  }

  .selected-rules-judge-bio {
    padding: 1rem;
    height: 24rem;
  }
}

/*******************************************/
/* BELOW 352px (THIN PHONES) */
/*******************************************/

@media (max-width: 22em) {
  .glance-container {
    width: 80%;
  }

  .rules-nav {
    gap: 1.2rem;
    padding: 2.4rem 1.8rem;
  }

  .rules-form div select {
    width: 15rem;
  }

  .official-rules::before {
    left: -30%;
  }

  .prompt-type .description,
  .prompt-type .description--small {
    padding: 3.6rem 4.8rem;
  }

  .prompt-type .genre-list {
    padding: 0 4.8rem;
  }

  .judge-rules-img {
    width: 6rem;
    height: auto;
  }

  .judge-rules-img--large {
    height: 12rem;
    width: auto;
  }

  .selected-rules-judge-bio {
    height: 12rem;
  }
}
