/*******************************************/
/* BELOW 1404px (smaller desktops) */
/*******************************************/
@media (max-width: 88em) {
  .first-stage-container {
    padding: 9.6rem 4.8rem;
  }
}

/*******************************************/
/* BELOW 1312px (even smaller desktops) */
/*******************************************/
@media (max-width: 82em) {
  .first-stage-container {
    padding: 9.6rem 2.2rem;
  }

  .battle-date-title {
    font-size: 3.6rem;
    padding: 1.6rem;
  }

  .battle-cards {
    padding: 2.4rem;
    gap: 1.5rem;
  }
}

/*******************************************/
/* BELOW 1200px (Landscape tabelts) */
/*******************************************/

@media (max-width: 75em) {
  .first-stage-container {
    padding: 9.6rem 0.5rem;
  }

  .battle-date-title {
    font-size: 2.8rem;
    padding: 1rem;
  }

  .battle-cards {
    padding: 2.4rem 0;
    gap: 1rem;
  }

  .duel-prompts {
    top: -31%;
  }
}

/*******************************************/
/* BELOW 992px (Tabelts) */
/*******************************************/

@media (max-width: 62em) {
  .first-stage-container {
    padding: 9.6rem 0rem;
  }

  .battle-date-title {
    font-size: 2.4rem;
    padding: 1rem;
  }

  .battle-cards {
    gap: 0.5rem;
  }

  .battle-profile {
    width: 15rem;
    height: 24.75rem;
    transform: translate(-15%, -75%);
  }

  .duel-prompts {
    top: -30%;
  }

  .second-stage {
    padding: 16rem 2.2rem 2.2rem 2.2rem;
  }
}

/*******************************************/
/* BELOW 720px (Smaller Tabelts) */
/*******************************************/

@media (max-width: 45em) {
  .date-text {
    font-size: 1.2rem;
  }

  .battle-profile {
    width: 10rem;
    height: 16.5rem;
    transform: translate(0%, -85%);
  }

  .battle-profile .description {
    font-size: 1.4rem;
  }

  .title-and-date-section {
    width: 100%;
    transform: translate(0%, -80%);
  }

  .battle-date-title {
    font-size: 2rem;
    padding: 0.5rem;
  }

  .battle-date-title .battle-icon {
    width: 4rem;
  }

  .countdown-unit-container {
    padding: 0.5rem;
  }

  .countdown-unit-container span {
    font-size: 2rem;
  }
  .countdown-unit-container p {
    font-size: 1.4rem;
  }

  .battle-cards {
    padding: 0rem;
  }

  .message-and-preview-wrapper {
    margin: 0;
  }

  .second-stage {
    padding: 8rem 1.4rem 1.4rem 1.4rem;
  }

  .judge-fun-image-wrapper {
    min-width: 5rem;
    min-height: 5rem;
  }

  .judge-fun-icon-group--image {
    width: 5rem;
    height: auto;
  }

  .duel-prompts {
    top: -30%;
    padding: 0;
  }

  .duel-story-select-button {
    padding: 1rem;
  }

  .battle-card--new {
    width: 20rem;
    height: 34rem;
  }

  .battle-card--top-text {
    font-size: 0.9rem;
    margin-top: 1.55rem;
    margin-bottom: 27.7rem;
    padding: 0rem 6rem 0 6rem;
  }

  .battle-card--bottom-text {
    font-size: 1.1rem;
    line-height: 1.2rem;
    padding: 0rem 5.3rem 0 5.3rem;
  }

  .lock-container {
    padding: 0.3rem;
    margin: 1.35rem;
  }

  .lock-icon {
    width: 3rem;
    height: 3rem;
  }
}

/*******************************************/
/* BELOW 544px (Phones) */
/*******************************************/

@media (max-width: 34em) {
  .first-stage-container {
    padding: 4.8rem 0rem;
  }

  .battle-profile {
    transform: translate(0%, -78%);
  }

  .time-and-date-container {
    grid-template-columns: 1fr;
  }

  .battle-date-title .battle-icon {
    width: 3rem;
  }

  .battle-date-title {
    justify-content: center;
    gap: 2rem;
    text-align: center;
    padding: 0.5rem 0.5rem 0 0.5rem;
  }

  .title-and-date-section {
    transform: translateY(-100%);
  }

  .countdown-grid {
    padding: 0.8rem 2rem;
    background-color: rgba(243, 245, 242, 0.5);
    border-radius: 3px;
    font-weight: 700;
  }

  .battle-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1.5rem;
    column-gap: 1.5rem;
    padding: 0.8rem;
  }

  .battle-card-container {
    gap: 0.5rem;
  }

  .battle-card-container:first-child {
    grid-row: 1;
    grid-column: span 2;
    justify-self: center;
  }

  .battle-card-container .battle-icon {
    width: 3.6rem;
  }

  .draw-prompts-controls-container {
    margin: 3.6rem auto;
    padding: 2.4rem 4rem;
  }

  .battle-card--unlocked::after {
    font-size: 2.4rem;
  }

  .draw-prompts-controls-container {
    align-items: center;
    margin: 1rem 0;
    padding: 1rem;
    gap: 0.5rem;
    min-height: 22rem;
    justify-content: space-around;
  }

  .redraw--youSure {
    width: 20rem;
  }

  .asset-amount {
    top: 50%;
    left: 50%;
  }

  .draw-amount {
    top: 100%;
    left: 40%;
  }

  .duel-prompts {
    top: -45%;
  }

  .duel-battle {
    margin: 4.8rem 0;
  }

  .judging-message {
    margin-top: 4.8rem;
    padding: 0 1rem;
  }

  .feedback-text-area {
    padding: 0.5rem;
  }

  .lock-container {
    padding: 0.3rem;
    margin: 1.45rem;
  }

  .battle-card--top-text {
    font-size: 0.9rem;
    margin-top: 1.7rem;
    margin-bottom: 27.7rem;
    padding: 0rem 6rem 0 6rem;
  }

  .battle-card--bottom-text {
    font-size: 1.1rem;
    line-height: 1.2rem;
    padding: 0rem 5.3rem 0 5.3rem;
  }
}

/*******************************************/
/* BELOW 352px (THIN PHONES) */
/*******************************************/

@media (max-width: 22em) {
  .title-and-date-section {
    transform: translateY(-100%);
  }

  .countdown-grid {
    padding: 0.8rem;
  }

  .battle-profile {
    width: 8rem;
    height: 13.2rem;
    transform: translate(0%, -75%);
  }

  .battle-profile .description {
    font-size: 1.1rem;
  }

  .draw-prompts-controls-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2.4rem;
    padding: 2.4rem 2rem;
    gap: 4.8rem;
  }

  .redraw-button {
    grid-row: 1;
    grid-column: span 2;
  }

  .social-share-icon--wrapper {
    flex-direction: column;
  }

  .redraw--youSure {
    width: 20rem;
  }

  .redraw-controls {
    grid-column: span 2;
  }

  .asset-amount {
    top: 65%;
    left: 50%;
  }

  .draw-amount {
    top: 110%;
    left: 40%;
  }

  .draw-container {
    margin: 0 auto;
  }

  .second-stage {
    padding: 12rem 0;
  }

  .judge-fun-icon-group--image {
    width: 5rem;
    height: auto;
  }

  .duel-prompts {
    top: -55%;
  }

  .ultra-thin-query {
    font-size: 1.2rem;
  }

  .story-judging-section {
    padding: 0 1.2rem 16rem 1.2rem;
  }

  .judging-message {
    margin-top: 4.8rem;
    padding: 0;
  }

  .battle-card--new {
    width: 16rem;
    height: 27.2rem;
  }

  .battle-card--top-text {
    font-size: 0.9rem;
    margin-top: 1.2rem;
    margin-bottom: 22rem;
    padding: 0rem 4.5rem 0 4.5rem;
  }

  .battle-card--bottom-text {
    font-size: 1rem;
    line-height: 1.2rem;
    padding: 0rem 3rem 0 3rem;
  }

  .battle-cards {
    row-gap: 1rem;
    column-gap: 0.5rem;
  }

  .lock-container {
    padding: 0.2rem;
    margin: 1.1rem;
  }

  .lock-icon {
    width: 2.5rem;
    height: 2.5rem;
  }
}
