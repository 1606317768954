.heading--join-battle {
  padding: 4.8rem 0;
}

.join-battle {
  overflow: hidden;
  position: relative;
  max-width: 120rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  border: 2px solid #1a1a1a;
  border-radius: 11px;
  margin: 0 auto;
  margin-bottom: 9.6rem;
  align-items: center;
  column-gap: 0;
}

.battle-select-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 80rem;
  gap: 2.4rem;
  margin-bottom: 0 !important;
  padding: 2rem 0.5rem 2rem 2rem;
  justify-self: center;
}

.battle-select-button {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  padding: 3.2rem;
  box-shadow: 0 1.8rem 4.8rem rgba(0, 0, 0, 0.15);
  transition: all 0.2s;
}

.battle-select-button:hover {
  cursor: pointer;
  box-shadow: 0 0 0 2px rgba(26, 26, 26, 0.24);
  transform: scale(0.995);
}

.battle-select-button--selected,
.battle-select-button--selected:hover {
  cursor: pointer;
  box-shadow: 0 0 0 3px #2a6e8c;
  transform: scale(0.98);
}

.battle-select-button--alreadyJoined,
.battle-select-button--alreadyJoined:hover {
  cursor: auto;
  box-shadow: 0 0 0 2px #e6ded3;
  transform: scale(0.98);
}

.checked-border {
  border-radius: 11px;
  border: #1a1a1a solid 2px;
  position: absolute;
  bottom: 5%;
  right: 5%;
}

.checked-border--white {
  border: #e6ded3 solid 2px;
}

.battle-checked {
  color: #2a6e8c !important;
}

.battle-select-button:hover .battle-checked {
  color: rgba(42, 111, 140, 0.6) !important;
  visibility: visible !important;
}

.battle-select-button--alreadyJoined:hover .battle-checked {
  visibility: hidden !important;
}

.battle-select-button--selected:hover .battle-checked {
  color: #112c38;
}

.battle-select-soonest::after {
  content: "Next up";
  position: absolute;
  top: 8%;
  right: -18%;

  text-transform: uppercase;
  color: #f3f5f2;
  font-size: 1.4rem;
  font-weight: 700;
  background-color: #2a6e8c;
  padding: 0.8rem 8rem;

  /* Use percentages for top and right
  to put it in the correct position*/
  transform: rotate(45deg);
}

.select-battle--controller {
  display: grid;
  grid-template-columns: 1fr;
  padding: 2.4rem 2.4rem 2.4rem 0;
  gap: 2.4rem;
  align-self: flex-start;
}

.thank-you-content {
  padding: 4.8rem;
  min-height: 40rem;
}

.select-battle--controller-total {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

.checkout-btn-and-error {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkout-btn {
  width: 100%;
  margin-bottom: 0.1rem;
}

.battle-name--select {
  padding: 1rem 0;
}

.checkout-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(1, 1, 1, 0.89);
  z-index: 999999999999;
}

.checkout-frame {
  background-color: #f3f5f2;
  width: 70rem;
  height: 92%;
  transform: translateY(+4%);
  margin: 0 auto;
  overflow: auto;
  padding: 4.2rem 6rem 1.8rem 6rem;
}

.checkout-title-and-price {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.6rem;
}

.checkout-title {
  margin: 0;
}

.checkout-battle-pair {
  justify-content: space-between;
  align-items: center;
}

.paypal-wrapper {
  max-width: 50rem;
  margin: 0 auto;
}

.conversion-flex {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.2rem;
}

.thank-you-img {
  padding: 4.8rem;
  height: 40rem;
}

.jb-flex--gap-small {
  gap: 2.4rem;
}

.jb-flex--gap-smaller {
  gap: 1.2rem;
}

.jb-description--larger {
  font-size: 2.8rem;
  line-height: 1.8;
}

.jb-description {
  font-size: 1.8rem;
  line-height: 1.8;
}

.jb-description--small {
  font-size: 1.6rem;
  line-height: 1.3;
}

.jb-description--smaller {
  font-size: 1.2rem;
  line-height: 1.3;
}

.jb-battle-icon {
  width: 5rem;
  height: 5rem;
  color: #1a1a1a;
}

.jb-battle-icon--small {
  width: 3rem;
  height: 3rem;
  color: #1a1a1a;
}

.jb-battle-name {
  /* flex: 1; */
  color: #1a1a1a;
  font-weight: 700;
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.75;
}
