/*******************************************/
/* BELOW 1200px (Landscape tabelts) */
/*******************************************/

@media (max-width: 75em) {
  .section-past-winners {
    padding: 5rem 1rem 5rem 2rem;
    gap: 1.2rem;
  }
  .past-winners-nav {
    justify-content: center;
  }
}

/*******************************************/
/* BELOW 992px (Tabelts) */
/*******************************************/

@media (max-width: 62em) {
  .past-winners-nav {
    gap: 6.4rem;
    padding: 4.8rem 0;
  }

  .past-winners-title {
    max-width: 70rem;
    margin-top: 3rem;
  }

  .past-winners-title .heading-secondary {
    font-size: 4.5rem;
  }

  .section-past-winners {
    gap: 1.2rem;
    padding: 3.6rem 0;
  }

  .profile-card {
    width: 19.2rem;
    height: 30.72rem;
  }

  .featured-past-winner-card {
    justify-self: center;
    width: 19rem;
    height: 30.72rem;
  }

  .past-winners-card--name {
    font-size: 1.1rem;
    margin-bottom: 25.2rem;
    margin-left: auto;
    margin-right: auto;
  }

  .past-winners-card--title {
    font-size: 1.2rem;
    margin-left: auto;
    margin-right: auto;
  }

  .past-winner-featured-section {
    margin: 0 1.2rem;
  }

  .featured-content-interview {
    grid-template-columns: 1fr;
  }

  .past-winner-judges {
    flex-direction: row;
    margin-top: 0;
    gap: 4rem;
  }
}

/*******************************************/
/* BELOW 720px (Smaller Tabelts) */
/*******************************************/

@media (max-width: 45em) {
  .section-past-winners {
    row-gap: 2.4rem;
    column-gap: 2.4rem;
  }

  .section-past-winners :nth-child(1) {
    justify-self: end;
  }

  .section-past-winners :nth-child(2) {
    justify-self: start;
  }

  .section-past-winners :nth-child(3) {
    justify-self: end;
  }

  .section-past-winners :nth-child(4) {
    justify-self: start;
  }

  .past-winners-nav {
    flex-direction: column;
    text-align: center;
    gap: 1.2rem;
    padding: 4.8rem 2.3rem 0 2.3rem;
  }

  .past-winners-form {
    grid-template-columns: 1fr 1fr;
    align-self: center;
  }

  .past-winners-form div {
    justify-self: center;
  }

  .past-winners-form div select {
    text-align: center;
  }

  .past-winners-nav .heading-primary {
    font-size: 8.6rem;
  }

  .past-winners-title {
    max-width: 35rem;
    margin-top: 8rem;
  }

  .past-winner-judges {
    flex-direction: column;
  }

  .featured-content-interview {
    margin-top: 4rem;
  }

  .past-winners-title .heading-secondary {
    font-size: 5rem;
  }
}

/*******************************************/
/* BELOW 544px (Phones) */
/*******************************************/

@media (max-width: 34em) {
  .past-winner-featured-section {
    margin: 0;
    padding: 3rem 0.5rem;
  }

  .section-past-winners {
    grid-template-columns: 1fr 1fr;
    gap: 1.8rem;
  }

  .past-winners-nav .heading-primary {
    font-size: 6.4rem;
  }

  .past-winners-title {
    margin-top: 0rem;
  }

  .featured-header-wrapper {
    min-height: 20rem;
  }

  .prompt-card-img {
    height: 20rem;
  }
}

/*******************************************/
/* BELOW 352px (THIN PHONES) */
/*******************************************/

@media (max-width: 22em) {
  .section-past-winners {
    grid-template-columns: 1fr;
  }

  .section-past-winners :nth-child(1) {
    justify-self: center;
  }

  .section-past-winners :nth-child(2) {
    justify-self: center;
  }

  .section-past-winners :nth-child(3) {
    justify-self: center;
  }

  .section-past-winners :nth-child(4) {
    justify-self: center;
  }

  .read-content {
    font-size: 1.6rem;
  }

  .past-winners-form {
    gap: 0.8rem;
  }

  .past-winners-form div .select--small {
    width: 12rem !important;
  }

  .past-winners-form div select {
    width: 18rem;
  }
}
